import React from 'react'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from 'react-router-dom'
import { useJwt } from 'react-jwt'

import HomePage from 'Pages/HomePage'
import LoginPage from 'Pages/LoginPage'
import PatientFormPage from 'Pages/PatientFormPage'
import EventListener from 'utils/EventListener'
import LoadingIcon from 'Components/LoadingIcon'

import AutoLogout from 'utils/AutoLogout'

import { useSelector } from 'react-redux'

const ProtectedRoute = ({ element }) => {
    const token = localStorage.getItem('token')
    const { isExpired } = useJwt(token || '')
    if (!token || isExpired) {
        return <Navigate to='/' />
    }
    return element
}

function App() {
    const handle = (event) => { }

    const isLoading = useSelector((state) => state.isLoadingView)

    return (
        <Router>
            <AutoLogout />
            {isLoading && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 9999,
                    }}
                >
                    <LoadingIcon />
                </div>
            )}
            <EventListener onClick={handle} />
            <Routes>
                <Route path='/' element={<LoginPage />} />
                <Route
                    path='/home'
                    element={<ProtectedRoute element={<HomePage />} />}
                />
                <Route
                    path='/form/:patientId/:id?'
                    element={<ProtectedRoute element={<PatientFormPage />} />}
                />
            </Routes>
        </Router>
    )
}

export default App
