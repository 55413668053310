export const styles1 = {
    box_heading1: {
        borderRadius: "8px 8px 0px 0px",
        background: "#17263E",
        fontSize: "20px",
        color: "white",
        fontFamily: "Roboto",
        fontWeight: "500",
        padding: "25px 0px 23px 10px",
    },
    support_text_11: {
        paddingLeft: "2px",
        color: "rgba(0, 0, 0, 0.50)",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "24px",
        letterSpacing: "0.15px",
    },
    box_content1: {
        marginTop: "5px",
        boxShadow:
            "0px 4px 6px -1px rgba(0, 0, 0, 0.3), 0px 5px 8px 0px rgba(0, 0, 0, 0.22)",
        borderRadius: "0px 0px 8px 8px",
    },
    question1: {
        marginTop: "14px",
        color: "var(--light-text-primary, rgba(0, 0, 0, 0.87))",
        fontFamily: "Roboto",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "24px",
        letterSpacing: "0.15px",
    },
    container1: {
        gap: "20px",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        padding: "0px 15px 20px 15px",
    },
    cont: {
        marginBottom: "10px",
        alignItems: "flex-start"
    },
    cont_1: {
        marginBottom: "10px",
        alignItems: "flex-start",
        pointerEvents :"none"
    },
};

export const styles2 = {
    gray_info_icon2: {
        cursor: "pointer",
        // padding: "15px 0px 0px 0px",
        position: "relative",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: "10px",
    },
    box_heading2: {
        borderRadius: "8px 8px 0px 0px",
        background: "#1A485E",
        fontSize: "20px",
        color: "white",
        fontFamily: "Roboto",
        fontWeight: "700",
        position: "relative",
    },
    box_content2: {
        backgroundColor: "#FFFF",
        marginTop: "5px",
        boxShadow:
            "0px 4px 6px -1px rgba(0, 0, 0, 0.3), 0px 5px 8px 0px rgba(0, 0, 0, 0.22)",
        borderRadius: "0px 0px 8px 8px",
    },
    profile_icon2: {
        padding: "18px 0px 15px 10px",
        position: "relative",
    },
    patient_name2: {
        padding: "10px 0px 15px 17px",
        margineLeft: "10px",
        position: "relative",
    },
    gender2: {
        padding: "10px 0px 15px 0px",
        paddingRight: "25px",
        position: "relative",
        display: "flex",
        justifyContent: "flex-end",
    },
    field2: {
        fontFamily: "Roboto",
        display: "flex",
        // position: "relative",
        alignItems:"center",
        paddingLeft: "10px",
        // paddingTop:'0px !important',
    },
    answer2: {
        paddingTop: "10px",
        paddingLeft: "5px",
        display: "flex",
    },
    answer3: {
        paddingTop: "10px",
        paddingLeft: "5px",
        display: "flex",
        width: "150px",
    },
    red_info_icon2: {
        cursor: "pointer",
        padding: "15px 0px 0px 0px",
        position: "relative",
        display: "flex",
        justifyContent: "end",
        marginRight: "14px",
    },
};

export const styles3 = {
    container: {
        gap: "5px",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        padding: "0px 15px 0px 15px",
    },
    box_heading: {
        borderRadius: "8px 8px 0px 0px",
        background: "#17263E",
        fontSize: "20px",
        color: "white",
        fontFamily: "Roboto",
        fontWeight: "500",
        padding: "25px 0px 23px 10px",
    },
    box_content: {
        marginTop: "5px",
        boxShadow:
            "0px 4px 6px -1px rgba(0, 0, 0, 0.3), 0px 5px 8px 0px rgba(0, 0, 0, 0.22)",
        borderRadius: "0px 0px 8px 8px",
    },
    question: {
        marginTop: "14px",
        color: "var(--light-text-primary, rgba(0, 0, 0, 0.87))",
        fontFamily: "Roboto",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "24px",
        letterSpacing: "0.15px",
    },
    support_text_1: {
        paddingLeft: "2px",
        color: "rgba(0, 0, 0, 0.50)",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "24px",
        letterSpacing: "0.15px",
    },
    support_text_2: {
        paddingLeft: "2px",
        color: "rgba(0, 0, 0, 0.50)",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "24px",
        letterSpacing: "0.15px",
    },
};

export const questionsMapping1 = {
    isRegularGPAvailable: {
        question: "Does the patient have a regular GP practice?",
        options: [
            { value: true, label: "Yes" },
            { value: false, label: "No" },
        ],
        type: "radio",
        info_text:
            "Patient can nominate a regular practice or practitioner they attend and consider to be their regular GP.  This question should be recorded as 'No' if finding a regular GP for the patient is facilitated as part of the UCC interaction.",
    },
    uccEntryPoint: {
        question:
            "How was the patient directed to the Medicare UCC? If patient confirms referral by another source, select most appropriate where possible",
        options: [
            {
                value: 1,
                label: "Walk-in",
            },
            {
                value: 2,
                label: "Local ED",
            },
            {
                value: 3,
                label: "Healthdirect or other phone line",
            },
            {
                value: 4,
                label: "Ambulance diversion",
            },
            {
                value: 5,
                label: "General Practitioner",
            },
            {
                value: 6,
                label: "Other health professional",
            },
            {
                value: 0,
                label: "Other",
            },
        ],
        type: "select",
        info_text:
            "This information can be confirmed through other sources of information e.g. paperwork accompanying patient etc.",
    },
    alternativePlaces: {
        question: "What alternative place would care have been sought from?",
        options: [
            { value: 1, label: "Local ED" },
            {
                value: 2,
                label: "Telephone or virtual triage service e.g. Healthdirect",
            },
            { value: 3, label: "Ambulance" },
            { value: 4, label: "General Practitioner" },
            {
                value: 5,
                label: "Other health professional",
            },
            {
                value: 6,
                label: "Would not have sought medical care",
            },
            { value: 0, label: "Other" },
        ],
        type: "select",
    },
    conditionTypeIdsJson: {
        question: "What type of presentation was treatment provided for?",
        sup_text:
            " Select the most appropriate category based on primary diagnosis and related treatment",
        options: [
            { value: 1, label: "Acute injury" },
            { value: 2, label: "Acute illness" },
            {
                value: 3,
                label: "Acute exacerbation of known chronic condition",
            },
            {
                value: 4,
                label: "Medicare UCC follow up appointment",
            },
            {
                value: 0,
                label: "Other e.g., routine management of known condition",
            },
        ],
        type: "radioGroup",
    },
    causeOfInjury: {
        question: "What was the cause of the patient's injury?",
        options: [
            {
                value: 1,
                label: "Transport related injury",
            },
            {
                value: 2,
                label: "Sport/exercise related injury",
            },
            {
                value: 3,
                label: "Intentional self harm related injury",
            },
            { value: 4, label: "Assault related injury" },
            { value: 0, label: "Other related injury" },
        ],
        type: "select",
        info_text:
            "Note this may be a sensitive question and should be handled carefully.",
    },
    clinicalHandover: {
        question: "How was a clinical handover provided to the patient's usual GP?",
        options: [
            {
                value: 1,
                label: (
                    <span>
                        Sent to usual GP/primary care provider via fax, secure messaging
                        etc.
                    </span>
                ),
            },
            {
                value: 2,
                label: "Uploaded to My Health Record",
            },
            {
                value: 3,
                label: (
                    <span>
                        Provided to patient
                        <span
                            style={{
                                color: "rgba(0, 0, 0, 0.50)",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                            }}
                        >
                            &nbsp; (patient declines handover to another service and/or
                            patient cannot or does not wish to nominate a regular GP to
                            handover to)
                        </span>
                    </span>
                ),
            },
            {
                value: 0,
                label: "Other",
            },
        ],
        type: "radioGroup",
        info_text:
            "A summary of patient medical history, diagnosis, treatment, and follow-up care after discharge should be provided to the patient's usual GP where ever possible; this may be the same information as provided to other services the patient is directed to (for immediate or later follow up)",
    },
    episodeEndStatus: {
        question:
            "Where was the patient directed to after receiving clinical care in the Medicare UCC?",
        options: [
            { value: 1, label: "Referred home" },
            {
                value: 2,
                label: "Referred to hospital ward",
            },
            { value: 3, label: "Referred to ED" },
            { value: 4, label: "GP referral" },
            { value: 5, label: "Did not wait" },
            { value: 6, label: "Left at own risk" },
            { value: 0, label: "Other" },
        ],
        type: "select",
        info_text:
            "Where a patient is immediately directed to go/or chooses to go after the patient episode ends.",
    },
    otherReferralIdsJson: {
        question:
            "What additional follow-up services, if any, was the patient referred to?",
        options: [
            { value: 1, label: "Allied health" },
            { value: 2, label: "Mental health services" },
            { value: 3, label: "Outpatient clinic" },
            {
                value: 4,
                label: "Specialist family, domestic and sexual violence services",
            },
            { value: 0, label: "Other" },
            { value: 5, label: "None" },
        ],
        type: "radioGroup",
        info_text:
            "Services the patient was referred to, related to their clinical presentation at the Medicare UCC.",
    },
};

export const questionsMapping3 = {
    languageIdsJson: {
        question: "What language does the patient speak at home?",
        options: [
            { value: "English", label: "English" },
            { value: "Mandarin", label: "Mandarin" },
            { value: "Arabic", label: "Arabic" },
            { value: "Vietnamese", label: "Vietnamese" },
            { value: "Cantonese", label: "Cantonese" },
            { value: "Punjabi", label: "Punjabi" },
            { value: "Greek", label: "Greek" },
            { value: "Italian", label: "Italian" },
            { value: "Hindi", label: "Hindi" },
            { value: "Spanish", label: "Spanish" },
            { value: "Nepali", label: "Nepali" },
            { value: "Tagalog", label: "Tagalog" },
            { value: "Korean", label: "Korean" },
            { value: "Urdu", label: "Urdu" },
            { value: "Tamil", label: "Tamil" },
            { value: "Filipino", label: "Filipino" },
            { value: "Sinhalese", label: "Sinhalese" },
            { value: "Gujarati", label: "Gujarati" },
            { value: "Malayalam", label: "Malayalam" },
            { value: "Indonesian", label: "Indonesian" },
            { value: "Other", label: "Other" },
        ],
        type: "select",
    },
    interpreterRequired: {
        question: "Was an interpreter used?",
        options: [
            { value: true, label: "Yes" },
            { value: false, label: "No" },
        ],
        type: "radio",
        info_text:
            "Whether an interpreter was required and used in the patient episode.  Includes use of informal interpreter (e.g. family member).  Note that best practice is to use a formal interpreting service (such as TIS) where possible.",
    },
    disabilityStatusIdsJson: {
        question:
            "Which of the following does the patient identify as best describing their disabilities?",
        sup_text:
            "  (Please select any that apply – you may select more than one.)",
        options: [
            {
                value: 1,
                label: (
                    <span>
                        <span name='CE1ID_disabilityStatusIdsJson1' >Sensory</span>
                        <span style={styles3.support_text_2}>
                            {" "}
                            (e.g. sight, hearing, speech)
                        </span>
                    </span>
                ),
            },
            {
                value: 2,
                label: (
                    <span>
                        <span name='CE1ID_disabilityStatusIdsJson2' >Intellectual</span>
                        <span style={styles3.support_text_2}>
                            {" "}
                            (e.g. difficulty learning or understanding)
                        </span>
                    </span>
                ),
            },
            {
                value: 3,
                label: (
                    <span>
                        <span name='CE1ID_disabilityStatusIdsJson3' >Physical</span>
                        <span style={styles3.support_text_2}>
                            {" "}
                            (e.g. breathing difficulties, chronic or recurrent pain, blackouts
                            or seizures, incomplete use of limbs)
                        </span>
                    </span>
                ),
            },
            {
                value: 4,
                label: (
                    <span>
                        <span name='CE1ID_disabilityStatusIdsJson4' >Psychosocial</span>
                        <span style={styles3.support_text_2}>
                            {" "}
                            (e.g. nervous or emotional conditions, social or behavioural
                            difficulties)
                        </span>
                    </span>
                ),
            },
            {
                value: 5,
                label: <span name='CE1ID_disabilityStatusIdsJson5' >Head injury, stroke, acquired brain injury</span>,
            },
            {
                value: 6,
                label: <span name='CE1ID_disabilityStatusIdsJson6' >Other</span>,
            },
            {
                value: 0,
                label: <span name='CE1ID_disabilityStatusIdsJson0' >None</span>,    
            },
        ],
        type: "radioGroup",
        info_text:
            "The categories below are disability groups based on underlying health conditions and on impairments, activity limitations and participation restrictions.",
    },
};