import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { userRequest2 } from "userRequest";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

const History = (patientId) => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const { id } = useParams();
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const postData = {
          externalId: patientId.patientId,
          pageNumber: 1,
          pageSize: 0,
        };
        await userRequest2
          .post(`/api/uccrecords/history`, postData)
          .then((response) => {
            const data = response.data.items;
            const dateList = data.map((item) => ({
              id: item.id,
              externalId: item.externalId,
              date: formatDate(item.dateOfPresentation),
            }));
            setData(dateList);
          });
      } catch (error) {
        if (error.response && error.response.status === 404) {
            toast.error("This form has been deleted.");
            navigate("/home");
          }else{
            console.error("Error fetching data:", error);
          }
      }
    };
    fetchUserData();
  }, [patientId.patientId]);

  const formatDate = (utcDate) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    const localDate = new Date(utcDate);
    return localDate.toLocaleDateString("en-AU", options);
  };

  return (
    <>
      <div style={styles.box_content}>
        <div style={styles.box_heading}>
          <Grid container spacing={0}>
            <Grid style={styles.patient_name}>
              Patient History at Medicare UCC
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid container spacing={0}>
            <Grid
              sx={{
                minHeight: "100px",
                maxHeight: "100px",
                overflow: "auto",
                overflowX: "hidden",
                "&::-webkit-scrollbar": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#1A485E",
                  borderRadius: "4px",
                },
              }}
              style={{
                ...styles.dateContainer,
              }}
              item
              xs={12}
            >
              {data.map((item) => (
                <div name="CE1ID_historyItem" key={item.id}>
                  <a
                    id="CE1ID_historyItem"
                    href={`/form/${encodeURIComponent(item.externalId)}/${
                      item.id
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      display: "contents",
                      textDecoration: "none",
                      color: "inherit",
                      cursor: "pointer",
                      pointerEvents:
                        item.id.toString() === id ? "none" : "auto",
                    }}
                    key={item.id}
                  >
                    <p
                      style={{
                        ...styles.field,
                        fontWeight:
                          item.id.toString() === id ? "bold" : "normal",
                      }}
                    >
                      <a
                        name="CE1ID_historyItem"
                        style={{ textDecoration: "none" }}
                        key={item.id}
                      >
                        {item.date}
                      </a>
                    </p>
                  </a>
                  {data.length !== data.length - 1 && (
                    <div
                      style={{
                        height: "4px",
                        background: "#ECEDEE",
                      }}
                    />
                  )}
                </div>
              ))}
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

const styles = {
  dateContainer: {
    marginBottom: "4px",
  },
  box_heading: {
    padding: "14px",
    borderRadius: "8px 8px 0px 0px",
    background: "#1A485E",
    fontSize: "20px",
    color: "white",
    fontFamily: "Roboto",
    fontWeight: "700",
  },

  box_content: {
    backgroundColor: "#FFFF",
    marginTop: "20px",
    boxShadow:
      "0px 4px 6px -1px rgba(0, 0, 0, 0.3), 0px 5px 8px 0px rgba(0, 0, 0, 0.22)",
    borderRadius: "0px 0px 8px 8px",
  },

  patient_name: {
    //padding: "11px 0px 11px 17px",
    margineLeft: "10px",
    position: "relative",
  },

  field: {
    paddingLeft: "10px",
    textAlign: "center",
    textDecoration: "none",
    color: "black",
    letterSpacing: "0.15px",
    height: "14px",
  },

  last_field: {
    fontWeight: "600",
    paddingLeft: "10px",
    paddingBottom: "20px",
  },
};

export default History;
