import React, { useRef, useState, useEffect } from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    IconButton,
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import SearchIcon from '@mui/icons-material/Search'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { styled } from '@mui/system'
import { InfoOutlined } from "@mui/icons-material";
import { userRequest, userRequest2 } from 'userRequest'

import { useSelector, useDispatch } from 'react-redux'
import { setMergedData, setFilteredData, setPreviousRouteOfForm, setIsConsented } from 'Services/actions'

import StatusBar1 from '../Assets/images/status-bar-1.svg'
import StatusBar2 from '../Assets/images/status-bar-2.svg'
import StatusBar3 from '../Assets/images/status-bar-3.svg'
import { Tooltip } from 'react-tooltip'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {ExportDateFetch} from "../utils/ExportDateFetch"

const RotatedErrorOutlineIcon = styled(ErrorOutlineIcon)({
    transform: 'rotate(180deg)',
})

const StyledIconButton = styled(IconButton)({
    '&:hover': {
        backgroundColor: 'transparent',
    },
})

const UserList = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const tableBodyRef = useRef();
    let mergedData = useSelector((state) => state.setMergedDataView)
    let backupMergedData = useSelector((state) => state.setFilterDataView)
    const currentDate = dayjs()
    let mergedDataFromApi = [];
    let appendedData = [];
    const [availablePages, setAvailablePages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('')
    const [createdDate, setCreatedDate] = useState(currentDate)
    const [isFetching, setIsFetching] = useState(false)
    const [countDown, setCountDown] = useState(0);
    const [exportDate, setExportDate] = useState(null);
    const handleSearchDate = (date) => {
        const formattedDate = dayjs(date)
        setSearchValue('')
        setCreatedDate(formattedDate)
    }

    const handleSearchChange = (event) => {
        if (!localStorage.getItem("validUrls")) {
            toast.error('Clinical server is not reachable.')
        }
        const newSearchValue = event.target.value
        setSearchValue(newSearchValue)
    }

    const handlePatientClick = (patient) => {
        dispatch(setPreviousRouteOfForm('patientFromUserList'))
        window.location.replace(`/form/${encodeURIComponent(patient.patientID)}/${patient.id}`)
    }

    useEffect(() => {
        mergedDataFromApi = []
        appendedData = []
        dispatch(setMergedData([]))
        mergedData = []

        dispatch(setIsConsented(null))
        if (!searchValue) {
            dispatch(setMergedData(backupMergedData))
        }
        else {
            setCurrentPage(1)
            fetchData(1, 15)
            const filteredPatientData = backupMergedData.filter((item) =>
                item.surname.toLowerCase().startsWith(searchValue.toLowerCase())
            )
            dispatch(setMergedData(filteredPatientData))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue])

    useEffect(() => {
        const fetch = async () => {
            const exportData = await ExportDateFetch();
            const { exportDateTimeReturn, countDownReturn } = exportData;
            setExportDate(exportDateTimeReturn);
            setCountDown(countDownReturn);
        }
        fetch();
    },[])
    const fetchData = async (pageNumber, pageSize) => {
        if (searchValue === '') {
            try {
                const searchPostData = {
                    patientExternalIds: [],
                    dateOfPresentation: dayjs(createdDate).format("YYYY-MM-DD"),
                    pageNumber: pageNumber,
                    pageSize: pageSize,
                }

                // Fetch data from Azure DB
                const azureResponse = await userRequest2.post(
                    '/api/uccrecords/search',
                    searchPostData
                )
                setAvailablePages(azureResponse.data.pageCount);
                const azureData = azureResponse.data.items
                const patientExternalIdsString = azureData
                    .map((item) => item.externalId)
                    .join(',')

                // Fetch data from UCC DB
                const encodedIds = encodeURIComponent(patientExternalIdsString)
                if (localStorage.getItem("validUrls")) {
                        const uccResponse = await userRequest.get(
                            `/PatientAPI/GetPatient?patientIDs=${encodedIds}`
                        )
                    const uccData = uccResponse.data

                        mergedDataFromApi = azureData
                            .map((azurePatient) => {
                                const interceptedData = uccData.find(
                                    (uccPatient) => azurePatient.externalId === uccPatient.patientID
                                )
                                if (interceptedData) {
                                    return { ...azurePatient, ...interceptedData }
                                } else {
                                    return null
                                }
                            })
                            .filter((item) => item !== null)

                        appendedData = [...mergedData, ...mergedDataFromApi]
                        dispatch(setMergedData(appendedData))
                        dispatch(setFilteredData(appendedData))
                }
                else {
                    toast.error('Clinical server is not reachable.')
                }

            } catch (error) {
                console.error(error)
            }
        } else {
            try {
                if (localStorage.getItem("validUrls")) {

                    //request patient data from ucc based on searchValue
                    const filterPatientResponse = await userRequest.get(
                        `/PatientAPI/FilterPatient?surname=${searchValue}&includeInactive=false&includeDeceased=false`
                    )
                    const filterPatientData = filterPatientResponse.data
                    const patientIdsFromUcc = filterPatientData
                        .map((uccPatient) => `${uccPatient.patientID}`)

                    //request data from azure based on patientIdsFromUcc
                    const searchPostUccModule = {
                        patientExternalIds: patientIdsFromUcc,
                        createdDate: createdDate.format('YYYY-MM-DD'),
                        pageNumber: pageNumber,
                        pageSize: pageSize,
                    }

                    const uccModuleResponse = await userRequest2.post(
                        '/api/uccrecords/search',
                        searchPostUccModule
                    )
                    const uccModuleData = uccModuleResponse.data.items
                    mergedDataFromApi = uccModuleData
                        .map((azurePatient) => {
                            const interceptedData = filterPatientData.find(
                                (uccPatient) => azurePatient.externalId === uccPatient.patientID
                            )
                            if (interceptedData) {
                                return { ...azurePatient, ...interceptedData }
                            } else {
                                return null
                            }
                        })
                        .filter((item) => item !== null)
                    appendedData = [...mergedData, ...mergedDataFromApi]
                    dispatch(setMergedData(appendedData))
                }
                else {
                    toast.error('Clinical server is not reachable.')
                }

            } catch (error) {
                console.error(error)
            }
        }        
    }
    // Add a scroll event listener
    const handleScroll = async () => {
        const tableBody = tableBodyRef.current;
        if (
            !isFetching && 
            (tableBody.scrollTop + tableBody.clientHeight >=
            tableBody.scrollHeight - 50)
        ) {
            setIsFetching(true);
           
            let nextPage = currentPage + 1;

            if (nextPage <= availablePages) {
                await fetchData(nextPage, 15);
                setCurrentPage(nextPage);
            }
            
            setIsFetching(false);
        }
    }


    useEffect(() => {
        mergedDataFromApi = []
        appendedData = []

        dispatch(setMergedData([]))
        mergedData = []
        dispatch(setFilteredData([]))
        setCurrentPage(1);
        fetchData(1, 15).catch((error) => console.error(error))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createdDate])

    const capitalizeName = (name) => {
        const words = name.split(' ')
        const capitalizedWords = words.map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        return capitalizedWords.join(' ')
    }

    return (
        <div className='top-container'>
            <div style={{
                display: "flex", gap: "4px", alignItems: "flex-end", justifyContent:"flex-end"
            }}>
                <div style={{ cursor: "pointer" }}>
                    <Tooltip
                        id="toolTip"
                        place="left"
                        style={{
                            zIndex: '9999',
                            padding: '6px 16px',
                            alignItems: 'center',
                            gap: '12px',
                            lineHeight: '143%',
                            color: '#FFFF',
                            backgroundColor: '#1A485E',
                        }}
                    />
                    <p
                        data-tooltip-id="toolTip"
                        data-tooltip-content='Next Data Submission Date'
                    >
                        <InfoOutlined
                            style={{
                                    width: "20px",
                                    height: "20px",
                                    fill: "rgba(0, 0, 0, 0.50)",
                                    marginTop: "2px"
                                }}
                        />
                    </p>
                </div>
                <p style={{ padding: "5px" }}>{exportDate?.format('DD/MM/YYYY')} &nbsp; {countDown === 0 ? '(Today)' : `(${countDown} Days)`}</p>
            </div> 
            <div style={styles.search_containers}>
                <div>
                    <div style={styles.search_containers_one}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                slotProps={{
                                    textField: { size: 'small' },
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderRadius: '8px',
                                            height: '40px',
                                            paddingBottom: '2px',
                                            border: 'none',
                                        },
                                        backgroundColor: '#FFFFFF',
                                        borderRadius: '8px',
                                    },
                                }}
                                value={createdDate}
                                onChange={handleSearchDate}
                                format='DD/MM/YYYY'
                            />
                        </LocalizationProvider>

                        <div className='name-search'>
                            <TextField
                                name='CE1ID_patientListSearch'
                                placeholder='Search in progress or complete forms'
                                value={searchValue}
                                onChange={handleSearchChange}
                                fullWidth
                                style={{
                                    width: '400px',
                                    borderRadius: '8px',
                                    backgroundColor: '#FFFFFF',
                                    border: 'none',
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <SearchIcon
                                            style={{
                                                marginRight: '8px',
                                                color: 'rgba(0, 0, 0, 0.54)',
                                            }}
                                        />
                                    ),
                                    style: {
                                        borderRadius: '8px',
                                        color: 'black',
                                        height: '39px',
                                    },
                                }}
                            />
                        </div>
                    </div>
                </div>
                <>
                    <div style={{ margin: 'auto 0px' }}>
                        <Tooltip
                            id='my-tooltip'
                            place='bottom'
                            style={{
                                zIndex: '9999',
                                width: '320px',
                                padding: '6px 16px',
                                alignItems: 'center',
                                gap: '12px',
                                lineHeight: '143%',
                                color: '#FFFF',
                                backgroundColor: '#1A485E',
                            }}
                        />
                        <p
                            data-tooltip-id='my-tooltip'
                            data-tooltip-content='This currently shows all forms commenced today. To view forms commenced on previous days, please navigate using the date filter.'
                        >
                            <StyledIconButton>
                                <RotatedErrorOutlineIcon />
                            </StyledIconButton>
                        </p>
                    </div>
                </>
            </div>

            <div className='table-container'>
                <TableContainer
                    component={Paper}
                    sx={{
                        maxHeight: 581,
                        overflowX: 'hidden',
                        '&::-webkit-scrollbar': {
                            width: 8,
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: '#F9F9F9',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#1A485E',
                            height: '2px',
                            borderRadius: 4,
                            paddingX: 6,
                        },
                    }}
                    style={{
                        borderRadius: '8px',
                        maxHeight: '581px',
                        border: '1px solid #A6A6A6',
                    }}
                    ref={tableBodyRef}
                    onScroll={handleScroll}
                >
                    <Table stickyHeader aria-label='sticky table'>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    align='left'
                                    style={{
                                        backgroundColor: '#F1F6F0',
                                        alignItems: 'center',
                                        boxShadow: '0px 5px #ECEDEE',
                                    }}
                                >
                                    Full Name
                                </TableCell>
                                <TableCell align='left' style={styles.table_header}>
                                    Date of Birth
                                </TableCell>
                                <TableCell align='left' style={styles.table_header}>
                                    Medicare Number
                                </TableCell>
                                <TableCell align='left' style={styles.table_header}>
                                    Form Completion Status
                                </TableCell>
                                <TableCell align='left' style={styles.table_header_last_cell}>
                                    Patient Status in Clinic
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody
                            className='table-body'
                            sx={{ overflow: 'scroll', backgroundColor: '#FFFFFF' }}
                        >
                            {mergedData?.map((patient, index) => (
                                <div
                                    style={{
                                        display: 'contents',
                                        textDecoration: 'none',
                                        color: 'inherit',
                                        cursor: 'pointer',
                                        borderBottom: '4px solid #ecedee',
                                    }}
                                    key={patient?.id}
                                    onClick={() => handlePatientClick(patient)}
                                >
                                    <TableRow
                                        name={`CE1ID_HomePagePatientListItem_${patient?.id}`}
                                        key={patient?.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell
                                            name={`CE1ID_HomePagePatientListItem_${patient?.id}`}
                                            scope='row'>
                                            {capitalizeName(patient?.firstName)} {capitalizeName(patient?.surname)}
                                        </TableCell>
                                        <TableCell
                                            name={`CE1ID_HomePagePatientListItem_${patient?.id}`}
                                            align='left'>
                                            {patient?.dob === '0001-01-01T00:00:00'
                                                ? ''
                                                : new Date(patient.dob)?.toLocaleDateString('en-AU')}
                                        </TableCell>
                                        <TableCell
                                            name={`CE1ID_HomePagePatientListItem_${patient?.id}`}
                                            align='left'>{patient.medicareNo}</TableCell>
                                        <TableCell
                                            name={`CE1ID_HomePagePatientListItem_${patient?.id}`}
                                            align='left'>
                                            <div style={styles.completionStatus_group}>
                                                {patient?.completionStatus >= 0 &&
                                                    patient?.completionStatus <= 30 && (
                                                        <img src={StatusBar1} alt='Status Bar 1' />
                                                    )}
                                                {patient?.completionStatus > 30 &&
                                                    patient?.completionStatus <= 70 && (
                                                        <img src={StatusBar2} alt='Status Bar 2' />
                                                    )}
                                                {patient?.completionStatus > 70 &&
                                                    patient?.completionStatus <= 100 && (
                                                        <img src={StatusBar3} alt='Status Bar 3' />
                                                    )}
                                                <div style={styles.completionStatus}>
                                                    {patient?.completionStatus}%
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            name={`CE1ID_HomePagePatientListItem_${patient?.id}`} align='left'>{patient?.waitingStatus}</TableCell>
                                    </TableRow>
                                </div>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <style>
                {`
          .top-container {
            border-color: #FFFFFF;
          }
          .table-container table tbody tr {
            box-shadow: 0px 7px 0px 0px rgb(225 225 225 / 42%);
            margin-bottom: 10px;
          }

          .table-container table tbody td {
            box-shadow: 0px 1px 0px 0px rgba(148, 148, 148, 255);
          }
        `}
            </style>
        </div>
    )
}
const styles = {
    completionStatus_group: {
        display: 'flex',
    },
    completionStatus: {
        paddingLeft: '5px',
    },
    margins: {
        paddingLeft: 62,
        paddingRight: 62,
        paddingTop: 20,
    },

    table_styles: {
        borderRadius: '8px',
        borderRight: '1px solid #A6A6A6',
    },

    table_header: {
        backgroundColor: '#F1F6F0',
        boxShadow: '0px 5px #ECEDEE',
        alignItems: 'center',
    },

    table_header_last_cell: {
        backgroundColor: '#F1F6F0',
        alignItems: 'center',
        borderRadius: '0px 8px 0px 0px',
        boxShadow: '0px 5px #ECEDEE',
    },

    dateSearch: {
        paddingTop: '20px',
        paddingLeft: '62px',
        width: '216px',
    },

    searchAlign: {
        display: 'flex',
    },

    nameSearch: {
        paddingLeft: '20px',
    },

    search: {
        display: 'flex',
        paddingTop: '30px',
        paddingLeft: '62px',
    },

    search_containers: {
        display: 'flex',
        justifyContent: 'space-between',
    },

    search_containers_one: {
        margin: '20px 0px',
        display: 'flex',
        gap: '10px',
    },

    table_cell: {
        borderBottom: '1px solid #A6A6A6',
        boxShadow: '0px 5px #ECEDEE',
    },

    '@media (max-width: 579px)': {
        table_styles: {
            borderRadius: '8px',
            height: '50px',
            width: '70px',
            margin: '100px',
            padding: '100px',
        },
    },
}

export default UserList
