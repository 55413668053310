const setPatientDataReducer = (state = {
    externalId: "",
    episodeEndStatus: null,
    isRegularGPAvailable: null,
    clinicalHandover: [],
    alternativePlaces: null,
    otherReferralIdsJson: [],
    conditionTypeIdsJson: [],
    disabilityStatusIdsJson: [],
    interpreterRequired: null,
    causeOfInjury: null,
    languageIdsJson: [],
    uccEntryPoint: null,
    completionStatus: 0,
    isConsented: null,
    tenantId: 0,
    createdBy: "",
    version: "",
    name: "",
    age: "",
    gender: "",
    address: "",
    indigenousStatus: "",
    medicareNumber: "",
    ethnicity: "",
    dateOfPresentation: "",
    timeOfPresentation: "",
    treatmentEndTime3: null
}, action) => {
    switch (action.type) {
        case "SETDATASET":
            return action.payload;
        case "UPDATEVERSION":
            return {
                ...state,
                version: action.payload.version,
            };
        default:
            return state
    }
};

export default setPatientDataReducer