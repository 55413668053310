// Import reducers
import isLoadingReducer from "./isLoadingReducer";
import setPatientDataReducer from "./patientDataReducer";
import setFilteredDataReducer from "./flteredDataReducer";
import setMergedDataReducer from "./mergedDataReducer";
import isConsentedReducer from "./isConsentedReducer";
import setPreviousRouteOfForm from "./previousRouteOfFormReducer";
import setAddPatientFormReducer from "./addPatientModalReducer";
import dateOfPresentationRuducer from "./dateOfPresentationRuducer";
// Import combineReducers
import { combineReducers } from "redux";

const allReducers = combineReducers({
    isLoadingView: isLoadingReducer,
    isConsentedView: isConsentedReducer,
    setPatientDataView: setPatientDataReducer,
    setFilterDataView: setFilteredDataReducer,
    setMergedDataView: setMergedDataReducer,
    setPreviousRouteOfFormView: setPreviousRouteOfForm,
    setAddPatientFormReducerView: setAddPatientFormReducer,
    dateOfPresentationRuducerView: dateOfPresentationRuducer
})

export default allReducers;