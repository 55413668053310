import { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import DateTimeService from './DateTimeService';
import { userRequest2 } from 'userRequest';

const useDecodedToken = () => {
    const [decodedToken, setDecodedToken] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decoded = jwtDecode(token);
            setDecodedToken(decoded);
        }
    }, []);

    return decodedToken;
};

const useEventListener = () => {
    let name;
    let outerText;
    const decodedToken = useDecodedToken();

    const saveEvent = async (tagName, name, trackerHref, outerText) => {
        const errorMessage = "Data not retrieved";
        let createdBy = decodedToken?.sub || errorMessage;
        let userRole = decodedToken?.job_role || errorMessage;

        const obj = {
            createdBy: createdBy,
            userRole: userRole,
            createdClientDateTimeString: DateTimeService.GetDateString(),
            createdClientDateTime: new Date(),
            tagName: tagName,
            outerText: outerText,
            link: trackerHref === "javascript:;" ? " " : trackerHref,
            name: name,
        };

        try {
            await userRequest2.post('/api/Events', obj);
        } catch (error) {
            console.error('Click event was not saved', error);
        }
    };

    const handle = (event) => {
        const element = event.target;
        const testid = element.getAttribute('data-testid')
        const placeholder = element.getAttribute("placeholder")
        const ariaLabel = element.getAttribute("aria-label")
        const id = element.getAttribute("id");
        name = element.getAttribute("name")
        const calenderPath =
          '<path d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"></path>';
        if (testid === "ArrowBackIcon") {
          name = "backButton";
        } else if (
          testid === "CalendarIcon" ||
          placeholder === "DD/MM/YYYY" ||
          element.outerHTML === calenderPath ||
          ariaLabel?.startsWith("Choose date,")
        ) {
          name = "datePicker";
          outerText = testid;
        } else if (element.tagName === "TD" || element.tagName === "TR"|| element.tagName === "TH") {
          name = element.getAttribute("name");
          if (name.startsWith("CE1ID_")) {
            name = name.replace("CE1ID_", "");
          }
        } else if (id === "CE1ID_historyItem") {
          name = 'historyItem';
        } else {
          if (
            name === undefined ||
            !name?.startsWith("CE1ID_") ||
            !element
          ) {
            return;
          } else {
              name = name.replace("CE1ID_", "");
          }
        }
        
        if (name === "") {
            name = element.value;
        }

        let link =
            element.dataset?.trackHref ||
            element.href ||
            element.getAttribute("data-hyperlink");

        saveEvent(
          element.tagName ? element.tagName : null,
          name,
          link ? link : null,
          outerText ? outerText : element?.outerText
        );
    };

    useEffect(() => {
        const handleClick = (event) => {
            handle(event);
        };

        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [decodedToken]);

     return handle, saveEvent;
};

export { useEventListener };
export default useEventListener;
