export const isLoading = (data) => {
    return {
        type: 'SETISLOADING',
        payload: data
    }
}

export const setPatientDatas = (data) => {
    return {
        type: 'SETDATASET',
        payload: data
    }
}

export const updateVersion = (data) => {
    return {
        type: 'UPDATEVERSION',
        payload: data
    }
}

export const setFilteredData = (data) => {
    return {
        type: 'SETFILTEREDDATA',
        payload: data
    }
}

export const setMergedData = (data) => {
    return {
        type: 'SETMERGEDDATA',
        payload: data
    }
}

export const setIsConsented = (data) => {
    return {
        type: 'SETISCONSENTED',
        payload: data
    }
}

export const setAddPatientModal = (data) => {
    return {
        type: 'SETADDPATIENTMODAL',
        payload: data
    };
}

export const setPreviousRouteOfForm = (data) => {
    return {
        type: 'SETPREVIOUSROUTE',
        payload: data
    };
}

export const setDateOfPresentationForPdf = (data) => {
    return {
        type: 'SETDATEOFPRESENTATION',
        payload: data
    };
}