import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  TableRow,
  TextField,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";

import search from "Assets/images/Search.svg";
import SearchPatientList from "utils/SearchPatientList";
import { userRequest2 } from "userRequest";
import { useNavigate } from "react-router";

import dayjs from "dayjs";

import { useDispatch } from "react-redux";
import { setAddPatientModal, setPreviousRouteOfForm } from "Services/actions";

const AddPatientFormSearch = ({ setClickedPatient }) => {
  const dispatch = useDispatch();
  const formattedDate = dayjs().format("YYYY-MM-DD").toString();
  const navigate = useNavigate();
  const { searchQuery, users, isLoading, error, handleSearch } =
    SearchPatientList();

  const handlePatientClick = async (patientID) => {
    const currentPage = 1;
    const pageSize = 0;
    const searchPostData = {
      patientExternalIds: [patientID],
      dateOfPresentation: formattedDate,
      pageNumber: currentPage,
      pageSize: pageSize,
    };

    try {
      await userRequest2
        .post(`/api/uccrecords/search`, searchPostData)
        .then((searchResponse) => {
          if (searchResponse.data.items.length > 0) {
            setClickedPatient(searchResponse.data);
            dispatch(setAddPatientModal(false));
          } else {
            dispatch(setPreviousRouteOfForm("patientFromSearch"));
            setTimeout(() => {
              navigate(`/form/${encodeURIComponent(patientID)}`);
            }, 2000);
          }
        });
    } catch (error) {
      console.error("Error checking form existence:", error);
    }
  };

  const capitalizeName = (name) => {
    const words = name.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
    return capitalizedWords.join(" ");
  };

  return (
    <div className="container">
      <TextField
        name="CE1ID_PatientSearch"
        placeholder="Search"
        value={searchQuery}
        onChange={handleSearch}
        fullWidth
        style={{ width: "45%", borderRadius: "8px" }}
        InputProps={{
          startAdornment: (
            <SearchIcon
              style={{ marginRight: "8px", color: "rgba(0, 0, 0, 0.54)" }}
            />
          ),
          style: {
            borderRadius: "8px",
            color: "black",
            height: "39px",
          },
        }}
      />
      {isLoading && <p>Loading...</p>}
      {error && (
        <div style={styles.not_found_container}>
          <img style={styles.search_img} src={search} alt="search" />
          <p style={styles.search_text_l1}>Patient cannot be found</p>
          <p style={styles.search_text_l2}>
            Please ensure you add the patient in your PMS
          </p>
        </div>
      )}
      {!isLoading && !error && (
        <>
          {users?.length > 0 ? (
            <div
              style={{
                maxHeight: "428px",
                marginBottom: "20px",
                marginTop: "28px",
                borderRadius: "8px",
              }}
            >
              <TableContainer
                sx={{
                  minWidth: 650,
                  maxHeight: 428,
                  "&::-webkit-scrollbar": {
                    width: "6px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#1A485E",
                    borderRadius: "4px",
                  },
                }}
                component={Paper}
              >
                <Table
                  sx={{
                    borderRadius: "8px",
                    minWidth: 650,
                    "&::-webkit-scrollbar": {
                      width: "6px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#1A485E",
                      borderRadius: "4px",
                    },
                  }}
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow
                      style={{
                        backgroundColor: "#1A485E",
                        color: "#FFFFFF",
                        border: "none",
                      }}
                    >
                      <TableCell style={styles.table_heading}>
                        Full Name
                      </TableCell>
                      <TableCell style={styles.table_heading}>DOB</TableCell>
                      <TableCell style={styles.table_heading}>
                        Medicare Number
                      </TableCell>
                      <TableCell style={styles.table_heading}>
                        Phone Number
                      </TableCell>
                      <TableCell style={styles.table_heading}>
                        Postcode
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users?.map((user, index) => (
                      <div
                        style={{
                          display: "contents",
                          textDecoration: "none",
                          color: "inherit",
                          cursor: "pointer",
                        }}
                        key={user?.patientID}
                        onClick={() => handlePatientClick(user?.patientID)}
                      >
                        <TableRow name="CE1ID_addNewPatientListItem">
                          <TableCell
                            name="CE1ID_addNewPatientListItem"
                            component="th"
                            scope="row"
                          >
                            {capitalizeName(user?.firstName)}{" "}
                            {capitalizeName(user?.surname)}
                          </TableCell>
                          <TableCell
                            name="CE1ID_addNewPatientListItem"
                            component="th"
                          >
                            {user?.dob === "0001-01-01T00:00:00"
                              ? ""
                              : new Date(user?.dob)?.toLocaleDateString(
                                  "en-AU"
                                )}
                          </TableCell>
                          <TableCell
                            name="CE1ID_addNewPatientListItem"
                            component="th"
                          >
                            {user?.medicareNo}
                          </TableCell>
                          <TableCell
                            name="CE1ID_addNewPatientListItem"
                            component="th"
                          >
                            {user?.mobilephone ||
                              user?.homephone ||
                              user?.workphone ||
                              ""}
                          </TableCell>
                          <TableCell
                            name="CE1ID_addNewPatientListItem"
                            component="th"
                          >
                            {user?.postCode}
                          </TableCell>
                        </TableRow>
                      </div>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          ) : (
            ""
          )}
        </>
      )}
      <style>
        {`
         .MuiTable-root td,
         .MuiTable-root th {
           border-right: none;
          }
          .css-8j6b76-MuiInputBase-root-MuiOutlinedInput-root {
            height: 39px;
            padding : 6px 12px;
            border-radius: 8px;
            margin-top : 9px;
          }
          .css-i4bv87-MuiSvgIcon-root{
            color : #1A485E;
          }
          .MuiTableHead-root {
            position: sticky;
            top: 0;
            background-color: #1A485E;
          }
        `}
      </style>
    </div>
  );
};
const styles = {
  not_found_container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "46px",
  },
  search_text_l1: {
    color: "#1A485E",
    fontSize: "20px",
    fontWeight: "700",
    margin: "0px",
    marginTop: "26px",
  },
  search_text_l2: {
    color: "rgba(124, 122, 122, 1)",
    fontSize: "16px",
    fontWeight: "400",
    margin: "0px",
    marginTop: "10px",
  },
  form_link: {
    textDecoration: "none",
    color: "#000000",
  },
  table_heading_bar: {
    backgroundColor: "#1A485E",
  },
  table_heading: {
    color: "#ffffff",
    backgroundColor: "#1A485E",
    border: "none",
  },
};
export default AddPatientFormSearch;
