import axios from 'axios'
import querystring from 'querystring';
import appInsights from 'Services/appInsights';

const clientId = 'patientAPIClient'
const clientSecret = 'patientAPISecret'

const baseUrl = localStorage.getItem('validUrls')
let formattedBaseUrl

if (baseUrl) {
    formattedBaseUrl = baseUrl.replace(/"/g, '');
}

const userRequest = axios.create({
    baseURL: `${formattedBaseUrl}`,
})

const userRequest2 = axios.create({
    baseURL: process.env.REACT_APP_UCC_BACKEND_BASE_URL,
})

const authorizationRequest = axios.create({
    baseURL: process.env.REACT_APP_MYPEN_AUTH_BASE_URL,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Basic ${btoa(`${clientId}:${clientSecret}`)}`,
    },
})

const renewToken = async () => {
    try {
        const refreshToken = localStorage.getItem('refresh_token');
        const requestBody = {
            grant_type: 'refresh_token',
            refresh_token: refreshToken,
            client_id: clientId,
            client_secret: clientSecret,
        };
        const body = querystring.stringify(requestBody);
        const response = await authorizationRequest.post('/connect/token', body);
        const data = response.data;
        localStorage.setItem('token', data.access_token);
        localStorage.setItem('refresh_token', data.refresh_token);
        return Promise.resolve();
    } catch (error) {
        localStorage.clear();
        window.location.replace('/');
        return Promise.reject(error);
    }
};

// Authorization header
const setAuthorizationHeader = (config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
};

// Interceptor to update Authorization header before each request
userRequest.interceptors.request.use((config) => {
    setAuthorizationHeader(config);
    return config;
});

userRequest2.interceptors.request.use((config) => {
    setAuthorizationHeader(config);
    return config;
});

// Interceptor to handle token renewal 
const handleTokenRenewal = async (error, request) => {
    if (error.response && error.response.status === 401) {
        await renewToken();
        setAuthorizationHeader(request.headers);
        return axios(request);
    }
    return Promise.reject(error);
};

const logErrorToAppInsights = (error, customMessage) => {
    const customProperties = {
        url: error.config.url,
        status: error.response ? error.response.status : 'Unknown',
        message: `[${localStorage.getItem('organisation_name')}] ${customMessage || error.message}`,
        timestamp: new Date().toISOString(),
    };

    appInsights.trackException({
        error,
        properties: customProperties,
    });
};

// Error handling interceptors
userRequest.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        if (error instanceof Error) {
            let customMessage = null;
            console.log("errorcode " + error.code);
            if (error.code === 'ERR_NETWORK' || error.code === 'ERR_CONNECTION_REFUSED') {
                customMessage = 'Clinic service is not reachable.';
            }
            logErrorToAppInsights(error, customMessage);
            await handleTokenRenewal(error, error.config);
            throw error;
        }
    }
);

userRequest2.interceptors.response.use(
    (response) => response,
    async (error) => handleTokenRenewal(error, error.config)
);

authorizationRequest.interceptors.response.use(
    (response) => response,
    async (error) => handleTokenRenewal(error, error.config)
);

export { userRequest, authorizationRequest, userRequest2 }
