const DateTimeService = {
    GetDateString() {
        const date = new Date();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const time = `${FixDoubleProblemAdd0(date.getDate())}/${month}/${date.getFullYear()} ${FixDoubleProblemAdd0(
            date.getHours()
        )}:${FixDoubleProblemAdd0(date.getMinutes())}:${FixDoubleProblemAdd0(date.getSeconds())}`;
        return time;
    }
};

function FixDoubleProblemAdd0(val) {
    let valstring = val.toString();
    valstring = valstring.length === 1 ? '0' + valstring : valstring;
    return valstring;
}

export default DateTimeService;