import dayjs from 'dayjs';

const dateOfPresentationReducer = (state = dayjs(), action) => {
    switch (action.type) {
        case 'SETDATEOFPRESENTATION':
            return action.payload;
        default:
            return state;
    }
};

export default dateOfPresentationReducer;
