import { Box } from '@mui/material'
import AddPatient from 'Components/AddPatient'
import NavBar from 'Components/Navbar'
import UserList from 'Components/UserList'

function HomePage() {
    return (
        <>
            <NavBar />
            <Box style={styles.container}>
                <AddPatient />
                <UserList />
            </Box>
            <style>
                <style>
                    {`
          body {
            background-color: #ECEDEE;
          }
        `}
                </style>
            </style>
        </>
    )
}
const styles = {
    arrowStyle: {
        fill: '#1A485E',
        cursor: 'pointer',
    },
    container: {
        margin: '61px 57px 0px 67px',
    },
    bg: {
        backgroundColor: '#ECEDEE',
        width: '100vw',
        height: '100vh',
    },
}
export default HomePage
