import { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  styled,
  Paper,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
  Select,
  Checkbox,
  Modal,
} from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import CloseIcon from "@mui/icons-material/Close";
import { DeleteOutline } from "@mui/icons-material";

import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setPatientDatas,
  updateVersion,
  setIsConsented,
  setPreviousRouteOfForm,
  setDateOfPresentationForPdf,
} from "Services/actions";

import { toast } from "react-toastify";

import NavBar from "Components/Navbar";
import BackButton from "Components/BackButton";
import History from "Components/History";
import { ExportDateFetch } from "../utils/ExportDateFetch";
import profileIcon from "Assets/images/profile-icon.svg";
import {
  styles1,
  styles2,
  styles3,
  questionsMapping1,
  questionsMapping3,
} from "utils/QuestionsMapping";

import { userRequest, userRequest2 } from "userRequest";

import { Form, Formik } from "formik";
import { Tooltip } from "react-tooltip";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PdfEditor from "Components/PdfEditor";

let updatedFormData;
let languages;
let completionPercentage;
let conditionTypes;
let disabilities;
let otherReferrals;
let clinicalHandovers;

const fields = {
  episodeEndStatus: null,
  isRegularGPAvailable: null,
  clinicalHandover: [],
  alternativePlaces: null,
  otherReferralIdsJson: [],
  conditionTypeIdsJson: [],
  disabilityStatusIdsJson: [],
  interpreterRequired: null,
  causeOfInjury: null,
  languageIdsJson: [],
  uccEntryPoint: null,
};

function processFormData(value) {
  let result;
  result = value ? JSON.stringify(value) : "";
  const parsedArray = result ? JSON.parse(result) : [];
  const filteredArray = Array.isArray(parsedArray)
    ? parsedArray.filter((item) => item !== null)
    : [];
  result = filteredArray.join(",");
  return result;
}

const QuestionOptions3 = ({
  field,
  options,
  type,
  formData,
  setFormData,
  isDisableForm,
  handleInputClick,
}) => {
  updatedFormData = formData;

  languages = processFormData(formData.languageIdsJson);
  conditionTypes = processFormData(formData.conditionTypeIdsJson);
  disabilities = processFormData(formData.disabilityStatusIdsJson);
  otherReferrals = processFormData(formData.otherReferralIdsJson);
  clinicalHandovers = processFormData(formData.clinicalHandover);

  const handleChange3 = (e) => {
    const updatedData = { ...formData };
    if (type === "radioGroup") {
      handleOptionChange3(field, e.target.value, updatedData);
    } else if (type === "radio") {
      updatedData[field] =
        updatedData[field] === e.target.value ? "" : e.target.value;
    } else {
      updatedData[field] = e.target.value;
    }
    setFormData(updatedData);
  };

  const handleOptionChange3 = (field, value, updatedData) => {
    if (updatedData !== undefined && updatedData !== null) {
      const updatedDataCopy = { ...updatedData };
      updatedDataCopy[field] = updatedDataCopy[field].includes(value)
        ? updatedDataCopy[field].filter((option) => option !== value)
        : [...updatedDataCopy[field], value];
      setFormData(updatedDataCopy);
    }
  };

  const handleRadioGroupClick = (field, value) => {
    const updatedData = { ...formData };
    updatedData[field] = updatedData[field].includes(value)
      ? updatedData[field].filter((option) => option !== value)
      : [...updatedData[field], value];
    setFormData(updatedData);
  };

  const renderSelectValue3 = (selected) => {
    if (Array.isArray(selected) && selected.length > 0) {
      let str = selected.join(", ");
      return str;
    } else {
      return "Select a Language";
    }
  };

  const [tooltipContent, setTooltipContent] = useState(
    renderSelectValue3(formData["languageIdsJson"])
  );

  useEffect(() => {
    setTooltipContent(renderSelectValue3(formData["languageIdsJson"]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData["languageIdsJson"]]);

  return (
    <>
      {type === "radio" && (
        <RadioGroup value={formData[field]} onChange={handleChange3}>
          <div>
            {options.map(({ value, label }) => (
              <FormControlLabel
                name={`CE1ID_${field}${value}`}
                key={value}
                value={value}
                control={<Radio />}
                label={label}
                disabled={formData.isSubmitted || isDisableForm}
                onClick={() => handleInputClick()}
              />
            ))}
          </div>
        </RadioGroup>
      )}

      {type === "select" && (
        <div>
          <Tooltip
            // name="CE1ID_Option"
            id="tooltipLanguage"
            place="bottom"
            style={styles.tool_tip_style}
          />
          <p
            data-tooltip-id="tooltipLanguage"
            data-tooltip-content={tooltipContent}
          >
            <Select
              name="CE1ID_languageSelect"
              disabled={formData.isSubmitted || isDisableForm}
              value={formData[field]}
              onChange={handleChange3}
              onClick={() => handleInputClick()}
              displayEmpty
              multiple
              renderValue={renderSelectValue3}
              style={styles.menu_props}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: "200px",
                  },
                },
              }}
            >
              {options.map(({ value, label }) => (
                <MenuItem key={value} value={value} style={{ padding: "0px" }}>
                  <a
                    name={`CE1ID_${field}${value}`}
                    style={styles.label_menu_item}
                  >
                    {label}
                  </a>
                </MenuItem>
              ))}
            </Select>
          </p>
        </div>
      )}

      {type === "radioGroup" && (
        <RadioGroup
          aria-label={field}
          value={formData[field]}
          onClick={() => {
            handleInputClick();
          }}
        >
          {options.map(({ value, label }) => (
            <FormControlLabel
              disabled={formData.isSubmitted || isDisableForm}
              id={value}
              key={value}
              style={
                formData.isSubmitted || isDisableForm
                  ? styles1.cont_1
                  : styles1.cont
              }
              value={value}
              control={
                <span
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    opacity: formData.isSubmitted || isDisableForm ? 0.5 : 1,
                  }}
                >
                  <Checkbox
                    name={`CE1ID_${field}${value}`}
                    disabled={formData.isSubmitted || isDisableForm}
                    icon={<PanoramaFishEyeIcon />}
                    checkedIcon={
                      <CheckCircleIcon
                        style={
                          formData.isSubmitted || isDisableForm
                            ? styles.check_1
                            : styles.check
                        }
                      />
                    }
                    style={{ paddingTop: "0px" }}
                    checked={formData[field].includes(value)}
                  />
                  <label
                    disabled={formData.isSubmitted || isDisableForm}
                    name={`CE1ID_${field}${value}`}
                    htmlFor={value}
                    style={{ paddingTop: "3px" }}
                  >
                    {label}{" "}
                  </label>
                </span>
              }
              checked={formData[field].includes(value)}
              onClick={() => {
                handleRadioGroupClick(field, value);
                handleInputClick();
              }}
            />
          ))}
        </RadioGroup>
      )}
    </>
  );
};

const QuestionOptions1 = ({
  field,
  options,
  type,
  formData,
  acuteClick,
  setFormData,
  isDisableForm,
  handleInputClick,
}) => {
  const handleOptionChange = (value) => {
    const updatedData = { ...formData };
    updatedData[field] = updatedData[field].includes(value)
      ? updatedData[field].filter((option) => option !== value)
      : [...updatedData[field], value];
    if (field === "conditionTypeIdsJson" && acuteClick && value === 1) {
      updatedData["causeOfInjury"] = "";
    }
    setFormData(updatedData);
  };

  const handleChange = (e) => {
    const updatedData = { ...formData };
    if (field === "episodeEndStatus") {
      const isNewAnswer = updatedData[field] !== e.target.value;
      if (
        isNewAnswer ||
        updatedData["treatmentEndTime3"] === "0001-01-01T00:00:00"
      ) {
        updatedData["treatmentEndTime3"] = dayjs()
          .format("YYYY-MM-DDTHH:mm:ss")
          .toString();
      }
    }
    updatedData[field] = e.target.value;
    setFormData(updatedData);
  };

  if (type === "radio") {
    return (
      <RadioGroup value={formData[field]} onChange={(e) => handleChange(e)}>
        <div style={{ display: "flex" }}>
          {options.map(({ value, label }) => (
            <FormControlLabel
              name={`CE1ID_${field}${label}`}
              disabled={formData.isSubmitted || isDisableForm}
              key={value}
              onClick={() => handleInputClick()}
              value={value}
              control={<Radio />}
              label={label}
            />
          ))}
        </div>
      </RadioGroup>
    );
  } else if (type === "select") {
    return (
      <Select
        disabled={formData.isSubmitted || isDisableForm}
        onClick={() => handleInputClick()}
        value={formData[field]}
        onChange={(e) => handleChange(e)}
        displayEmpty
        renderValue={(value) => {
          const selectedOption = options.find(
            (option) => option.value === value
          );
          return selectedOption ? selectedOption.label : "Select one";
        }}
        style={styles.menu_props}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: "200px",
            },
          },
        }}
      >
        {options.map(({ value, label }) => (
          <MenuItem key={value} value={value} style={{ padding: "0px" }}>
            <a name={`CE1ID_${field}${value}`} style={styles.label_menu_item}>
              {label}
            </a>
          </MenuItem>
        ))}
      </Select>
    );
  } else if (type === "radioGroup") {
    return (
      <RadioGroup
        aria-label={field}
        value={formData[field]}
        onClick={() => handleInputClick()}
      >
        {options.map(({ value, label }) => (
          <FormControlLabel
            name={`CE1ID_${field}${label}`}
            disabled={formData.isSubmitted || isDisableForm}
            id={value}
            key={value}
            style={
              formData.isSubmitted || isDisableForm
                ? styles1.cont_1
                : styles1.cont
            }
            value={value}
            control={
              <span
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  opacity: formData.isSubmitted || isDisableForm ? 0.5 : 1,
                }}
              >
                <Checkbox
                  name={`CE1ID_${field}${value}`}
                  disabled={formData.isSubmitted || isDisableForm}
                  icon={<PanoramaFishEyeIcon />}
                  checkedIcon={
                    <CheckCircleIcon
                      style={
                        formData.isSubmitted || isDisableForm
                          ? styles.check_1
                          : styles.check
                      }
                    />
                  }
                  style={{ paddingTop: "0px" }}
                  checked={formData[field].includes(value)}
                />

                <label
                  htmlFor={value}
                  style={{ paddingTop: "3px" }}
                  disabled={formData.isSubmitted || isDisableForm}
                  name={`CE1ID_${field}${value}`}
                >
                  {label}{" "}
                </label>
              </span>
            }
            onClick={() => {
              handleOptionChange(value);
              handleInputClick();
            }}
          />
        ))}
      </RadioGroup>
    );
  }
  return null;
};

function PatientFormPage() {
  const values = useSelector((state) => state.setPatientDataView);
  const consent = useSelector((state) => state.isConsentedView);
    const dateOfPresentationForPdf = useSelector(
    (state) => state.dateOfPresentationRuducerView
  );
  const dispatch = useDispatch();
  const { id, patientId } = useParams();
  const navigate = useNavigate();
  const formattedDate = dayjs().format("YYYY-MM-DDTHH:mm:ss").toString();
  const currentDate = dayjs();
  let dateOfpresentationForDatabase;

  const [dateOfPresentation, setDateOfPresentation] = useState(currentDate);
  const [formValuesChanged, setFormValuesChanged] = useState(false);
  const [acuteClick, setAcuteClick] = useState(false);
  const [isDisableForm, setDisableForm] = useState(false);
  const [consentError, setConsentError] = useState(false);
  const [countDown, setCountDown] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isOpenDeleteModel, setOpenDeleteModel] = useState(false);
  const [exportDate, setExportDate] = useState(null);
  const [previousWeekDate, setPreviousWeekDate] = useState(null);

  const handleDateOfPresentation = async (date) => {
    const formattedDate = dayjs(date);
    setDateOfPresentation(formattedDate);
    dispatch(setDateOfPresentationForPdf(formattedDate));
    dateOfpresentationForDatabase = formattedDate;
    await savePatientDataToDatabase();
  };
  useEffect(() => {
    const fetch = async () => {
      const exportData = await ExportDateFetch();
      const { weekStartDateReturn, exportDateTimeReturn, countDownReturn } =
        exportData;
      setPreviousWeekDate(weekStartDateReturn);
      setExportDate(exportDateTimeReturn);
      setCountDown(countDownReturn);
    };
    fetch();
  }, []);
  const CustomButton = styled(Button)({
    height: "3%",
    borderRadius: "4px",
    //color:
    //  consent === null ? "#00000042" : consent === true ? "#FFFFFF" : "#1A485E",
    backgroundColor: "#1A485E",
    //backgroundColor:
    //  consent === null ? "#0000001F" : consent === true ? "#1A485E" : "#F3F6CC",
    boxShadow:
      "0px 1px 5px 0px rg ba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)",
    width: "165px",
    letterSpacing: "0.15px",
    "&: hover": {
      backgroundColor: "#153d50",
      //backgroundColor:
      //  consent === null
      //    ? "#00000042"
      //    : consent === true
      //    ? "#153d50"
      //    : "#E6E9A4",
    },
  });

  const handleInputClick = () => {
    if (consent === null) {
      setConsentError(true);
    }
  };

  useEffect(() => {
    if (updatedFormData?.conditionTypeIdsJson?.includes(1)) {
      setAcuteClick(true);
    } else {
      setAcuteClick(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedFormData, formValuesChanged]);

  const savePatientDataToDatabase = async () => {
    setDisableForm(true);
    let postResponse;

    if (!id) {
      const postData = {
        externalId: patientId,
        createdDateTimeClient: formattedDate,
        isConsented: consent,
      };

      try {
        postResponse = await userRequest2.post(`/api/uccrecords`, postData);
      } catch (error) {
        console.error("Error creating new record:", error);
        return;
      }

      dispatch(setPreviousRouteOfForm("patientFromSearch"));
      window.location.replace(
        `/form/${encodeURIComponent(patientId)}/${postResponse.data.id}`
      );
    }

    let nonEmptyCount = Object.keys(fields).reduce((count, key) => {
      const value = values[key];

      if (
        value !== null &&
        value !== "" &&
        !(Array.isArray(value) && value.length === 0)
      ) {
        if (key === "causeOfInjury" && !acuteClick) {
          return count;
        }
        return count + 1;
      }
      return count;
    }, 0);

    let totalFields = Object.keys(fields).length;

    if (acuteClick === false) {
      totalFields--;
    }

    if (totalFields <= 0) {
      completionPercentage = 0;
    } else {
      completionPercentage = Math.min(
        100,
        Math.round((nonEmptyCount / totalFields) * 100)
      );
    }

    const mappedData = {
      id: id,
      // patientID: 0,
      externalId: values.externalId ? values.externalId : "",
       episodeEndStatus: values.episodeEndStatus,
      isRegularGPAvailable:
        values.isRegularGPAvailable !== null
          ? JSON.parse(values.isRegularGPAvailable)
          : null,
      //clinicalHandover: values.clinicalHandover
      //  ? values.clinicalHandover
      //  : null,
      clinicalHandover: clinicalHandovers,
      alternativePlaces: values.alternativePlaces,
      otherReferralIdsJson: otherReferrals,
      conditionTypeIdsJson: conditionTypes,
      disabilityStatusIdsJson: disabilities,
      interpreterRequired:
        values.interpreterRequired !== null
          ? JSON.parse(values.interpreterRequired)
          : null,
      causeOfInjury: values.causeOfInjury,
      languageIdsJson: languages,
      uccEntryPoint: values.uccEntryPoint,
      completionStatus: completionPercentage,
      isConsented: consent,
      version: values.version ? values.version : "",
      createdBy: localStorage.getItem("sub"),
      updatedDatetimeclient: formattedDate,
      treatmentEndTime3: values.treatmentEndTime3,
      dateOfPresentation: dateOfpresentationForDatabase
        ? dateOfpresentationForDatabase.format("YYYY-MM-DDTHH:mm:ss")
        : null,
    };

    if (mappedData.version != "" && mappedData.id) {
      try {
        await userRequest2
          .put(`/api/uccrecords/update`, mappedData)
          .then(function (response) {
            dispatch(updateVersion(response.data));
          });
      } catch (error) {
        if (error.response && error.response.status === 409) {
          try {
            await retryFetchData();
            toast.error("Someone has updated the form.");
            toast.error("Updated data retrieved");
          } catch (error) {
            console.error("Error retrying fetchData:", error);
          }
        }
        if (error.response && error.response.status === 404) {
          toast.error("This form has been deleted.");
          navigate("/home");
        }
        if (error.response && error.response.status === 423) {
          try {
            await retryFetchData();
            toast.error("Unable to update since this form has been submitted.");
          } catch (error) {
            console.error("Error retrying fetchData:", error);
          }
        } else {
          console.error("Error saving data to ucc", error);
        }
      }
    }
    setDisableForm(false);
  };

  const retryFetchData = () => {
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        try {
          await fetchData();
          resolve();
        } catch (error) {
          reject(error);
        }
      }, 1000);
    });
  };

  useEffect(() => {
    if (formValuesChanged) {
      const saveDataWithLatency = setTimeout(() => {
        savePatientDataToDatabase();
        setFormValuesChanged(false);
      }, 1000);

      return () => clearTimeout(saveDataWithLatency);
    }
  }, [updatedFormData, formValuesChanged, consent]);

  const calculateAge = (dob) => {
    if (dob === "0001-01-01T00:00:00") {
      return "-";
    }
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();

    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };
  const setNames = (firstName, surname) => {
    if (firstName) {
      if (surname) {
        return firstName + " " + surname;
      } else {
        return firstName;
      }
    } else if (surname) {
      return surname;
    }
    return "";
  };
  const setGender = (sex) => {
    if (sex === "F" || sex === "f" || sex === 2) {
      sex = "Female";
    } else if (sex === "M" || sex === "m" || sex === 1) {
      sex = "Male";
    } else if (sex === "X" || sex === "x" || sex === 3) {
      sex = "Other";
    } else {
      sex = "Not stated";
    }
    return sex;
  };

  const setIndigenousStatus = (status) => {
    if (!status) {
      return;
    } else if (status === 1) {
      return "Aboriginal but not Torres Strait Islander";
    } else if (status === 2) {
      return "Torres Strait Islander but not Aboriginal";
    } else if (status === 3) {
      return "Both Aboriginal and Torres Strait Islander";
    } else if (status === 4) {
      return "Neither Aboriginal nor Torres Strait Islander";
    } else if (status === 0 || status === 9) {
      return "Not Stated/inadequately described";
    }
    return status;
  };

  const setTimeOfPresentation = (timeString) => {
    let elements = timeString.split(",");
    timeString = elements[0];
    const hours = timeString.substr(0, 2);
    const minutes = timeString.substr(2, 2);
    const seconds = timeString.substr(4, 2);

    const formattedTime = `${hours}:${minutes}:${seconds}`;
    const date = new Date(`2000-01-01T${formattedTime}`);

    const formattedTime12Hour = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });
    const amPm = formattedTime12Hour.slice(-2).toLowerCase();
    return formattedTime12Hour.slice(0, -2) + amPm;
  };

  const fetchData = async () => {
    if (id) {
      try {
        const response = await userRequest2.get(`/api/uccrecords/${id}`);
        const encoResponse = response.data;
        const patientID = encoResponse.externalId;
        if (encoResponse) {
          const formattedDate = dayjs(encoResponse?.dateOfPresentation);
          setDateOfPresentation(formattedDate);
          dispatch(setDateOfPresentationForPdf(formattedDate));
        }
        const patientResponse = await userRequest.get(
          `/PatientAPI/GetPatient?patientIDs=${encodeURIComponent(patientID)}`
        );
        const uccResponse = patientResponse.data[0];

        if (uccResponse) {
          const {
            firstName,
            surname,
            dob,
            sex,
            address,
            atsi,
            medicareNo,
            ethnicity,
            time_of_presentation,
          } = uccResponse;
          const retrivedPatientData = {
            name: setNames(firstName, surname),
            age: dob ? calculateAge(dob) : "-",
            gender: sex ? setGender(sex) : "-",
            address: address || "-",
            indigenousStatus: atsi ? setIndigenousStatus(atsi) : "-",
            medicareNumber: medicareNo || "-",
            ethnicity: ethnicity?.[0] ?? "-",
            timeOfPresentation:
              time_of_presentation && time_of_presentation[0]
                ? setTimeOfPresentation(time_of_presentation)
                : "-",
          };

          const {
            episodeEndStatus,
            isRegularGPAvailable,
            //clinicalHandover,
            alternativePlaces,
            interpreterRequired,
            causeOfInjury,
            uccEntryPoint,
            completionStatus,
            tenantId,
            updatedBy,
            createdBy,
            version,
            isSubmitted,
            isConsented,
            treatmentEndTime3,
          } = encoResponse;
          const dateOptions = {
            day: "numeric",
            month: "long",
            year: "numeric",
          };
          retrivedPatientData.externalId = encoResponse.externalId || "";
          retrivedPatientData.episodeEndStatus =
            episodeEndStatus !== null ? episodeEndStatus : null;
          retrivedPatientData.isRegularGPAvailable =
            isRegularGPAvailable !== null ? isRegularGPAvailable : null;
          retrivedPatientData.clinicalHandover = encoResponse.clinicalHandover
            ? encoResponse.clinicalHandover.split(",").map(Number)
            : [];
          retrivedPatientData.alternativePlaces = alternativePlaces;
          retrivedPatientData.otherReferralIdsJson =
            encoResponse.otherReferralIdsJson
              ? encoResponse.otherReferralIdsJson.split(",").map(Number)
              : [];
          retrivedPatientData.conditionTypeIdsJson =
            encoResponse.conditionTypeIdsJson
              ? encoResponse.conditionTypeIdsJson.split(",").map(Number)
              : [];
          retrivedPatientData.disabilityStatusIdsJson =
            encoResponse.disabilityStatusIdsJson
              ? encoResponse.disabilityStatusIdsJson.split(",").map(Number)
              : [];
          retrivedPatientData.interpreterRequired =
            interpreterRequired !== null ? interpreterRequired : null;
          retrivedPatientData.causeOfInjury = causeOfInjury;
          retrivedPatientData.languageIdsJson = encoResponse.languageIdsJson
            ? encoResponse.languageIdsJson.split(",")
            : [];
          retrivedPatientData.uccEntryPoint = uccEntryPoint;
          retrivedPatientData.completionStatus = completionStatus || 0;
          retrivedPatientData.isConsented = consent || isConsented;
          retrivedPatientData.tenantId = tenantId || 0;
          retrivedPatientData.updatedBy = updatedBy || "";
          retrivedPatientData.createdBy = createdBy || "";
          retrivedPatientData.version = version || "";
          retrivedPatientData.isSubmitted = isSubmitted;
          retrivedPatientData.treatmentEndTime3 = treatmentEndTime3;
          retrivedPatientData.createdDateTimeClient =
            encoResponse.createdDateTimeClient &&
            encoResponse.createdDateTimeClient != "0001-01-01T00:00:00"
              ? new Date(encoResponse.createdDateTimeClient).toLocaleDateString(
                  "en-GB",
                  dateOptions
                )
              : new Date().toLocaleDateString("en-GB", dateOptions);
          dispatch(setIsConsented(encoResponse.isConsented));
          dispatch(setPatientDatas(retrivedPatientData));
          setAcuteClick(retrivedPatientData.conditionTypeIdsJson.includes(1));
          setIsSubmitted(encoResponse.isSubmitted);
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          toast.error("This form has been deleted.");
          navigate("/home");
        } else {
          console.error("Error fetching data:", error);
        }
      }
    } else if (!id && patientId) {
      setDisableForm(true);
      try {
        const patientResponse = await userRequest.get(
          `/PatientAPI/GetPatient?patientIDs=${encodeURIComponent(patientId)}`
        );
        const uccResponse = patientResponse.data[0];

        if (uccResponse) {
          const {
            firstName,
            surname,
            dob,
            sex,
            address,
            atsi,
            medicareNo,
            ethnicity,
            time_of_presentation,
          } = uccResponse;
          const dateOptions = {
            day: "numeric",
            month: "long",
            year: "numeric",
          };

          const retrivedPatientData = {
            name:
              (firstName &&
                (surname ? `${firstName} ${surname}` : firstName)) ||
              "",
            age: dob ? calculateAge(dob) : "-",
            gender: sex ? setGender(sex) : "-",
            address: address || "-",
            indigenousStatus: atsi ? setIndigenousStatus(atsi) : "-",
            medicareNumber: medicareNo || "-",
            ethnicity: ethnicity?.[0] ?? "-",
            timeOfPresentation:
              time_of_presentation && time_of_presentation[0]
                ? setTimeOfPresentation(time_of_presentation)
                : "-",
          };

          retrivedPatientData.externalId = "";
          retrivedPatientData.episodeEndStatus = null;
          retrivedPatientData.isRegularGPAvailable = null;
          retrivedPatientData.clinicalHandover = [];
          retrivedPatientData.alternativePlaces = null;
          retrivedPatientData.otherReferralIdsJson = [];
          retrivedPatientData.conditionTypeIdsJson = [];
          retrivedPatientData.disabilityStatusIdsJson = [];
          retrivedPatientData.interpreterRequired = null;
          retrivedPatientData.causeOfInjury = null;
          retrivedPatientData.languageIdsJson = [];
          retrivedPatientData.uccEntryPoint = null;
          retrivedPatientData.completionStatus = 0;
          retrivedPatientData.isConsented = null;
          retrivedPatientData.tenantId = 0;
          retrivedPatientData.updatedBy = "";
          retrivedPatientData.createdBy = "";
          retrivedPatientData.version = "";
          retrivedPatientData.isSubmitted = null;
          retrivedPatientData.createdDateTimeClient =
            new Date().toLocaleDateString("en-GB", dateOptions);
          dispatch(setPatientDatas(retrivedPatientData));
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          toast.error("This form has been deleted.");
          navigate("/home");
        } else {
          console.error("Error fetching data:", error);
        }
      }
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const capitalizeName = (name) => {
    const words = name.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
    return capitalizedWords.join(" ");
  };

  const CustomDeleteButton = styled(Button)(({ bgColor }) => ({
    border: !bgColor && "2px solid #000000",
    color: bgColor ? "#FFFFFF" : "#1A485E",
    borderRadius: "4px",
    backgroundColor: bgColor || "#FFFFFF",
    boxShadow:
      "0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)",
    width: "165px",
    letterSpacing: "0.15px",
    "&:hover": {
      backgroundColor: bgColor ? "#092939" : "#ECF9FF",
    },
  }));

  const RotatedErrorOutlineIcon = styled(ErrorOutlineIcon)({
    transform: "rotate(180deg)",
    color: "#757575",
    width: "40px",
    height: "40px",
  });

  const deleteReqBody = {
    id: id,
    updatedDateTimeClient: dayjs().format("YYYY-MM-DDTHH:mm:ss").toString(),
  };

  const handleDeleteForm = async () => {
    try {
      await userRequest2
        .delete("/api/uccrecords/delete", {
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify(deleteReqBody),
        })
        .then(function () {
          setOpenDeleteModel(false);
          navigate("/home");
        });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        toast.error("This form has been deleted.");
        setOpenDeleteModel(false);
        navigate("/home");
      }
      if (error.response && error.response.status === 423) {
        toast.error("Unable to delete since this form has been submitted.");
        setOpenDeleteModel(false);
        navigate("/home");
      } else {
        console.error(error);
      }
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#ECEDEE",
        marginBottom: "0px",
      }}
    >
      <NavBar />
      <div style={styles.container}>
        <Box>
          <BackButton setAcuteClick={setAcuteClick} />
          <Box style={styles.top_bar}>
            <div style={styles.top_bar_heading}>Manage Patients</div>
          </Box>
          <Divider style={styles.horizon_line} />
        </Box>
        <Box>
          <div style={styles.second_bar}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <div style={styles.second_bar_heading}>Patient Form</div>
              </Grid>
              <Grid item xs={4} />
              <Grid
                item
                xs={4}
                style={{
                  paddingTop: "0px",
                  marginTop: "16px",
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: "2%",
                }}
              >
                <div style={styles.buttons}>
                  {!(consent === null || consent === undefined) && (
                    <div>
                      <PdfEditor
                        dateOfPresentation={dateOfPresentationForPdf}
                        data={values}
                      />
                    </div>
                  )}
                  {!(
                    isSubmitted ||
                    consent === null ||
                    consent === undefined
                  ) && (
                    <div
                      style={styles.icon}
                      onClick={() => setOpenDeleteModel(true)}
                    >
                      <DeleteOutline
                        // name="CE1ID_DeleteIcon"
                        style={{
                          fill: "#17263E",
                          width: "22px",
                          height: "22px",
                        }}
                      />
                      <div name="CE1ID_DeleteIcon" style={styles.icon_text}>
                        Delete
                      </div>
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        </Box>
        <Modal open={isOpenDeleteModel}>
          <Box style={deleteModelStyles.modal}>
            <Box style={deleteModelStyles.modalHeader}>
              <p style={deleteModelStyles.heading}>Form Delete</p>
              <Button
                // name="CE1ID_PatientSearchCloseButton"
                // onClick={closeModal}
                style={deleteModelStyles.closeIcon}
                onClick={() => setOpenDeleteModel(false)}
              >
                <CloseIcon
                  id="closeButton"
                  // name="CE1ID_PatientSearchCloseButton"
                  sx={{ color: "#FFFFFF" }}
                  style={{ zIndex: "-1" }}
                />
              </Button>
            </Box>

            <>
              <div style={deleteModelStyles.contentContainer}>
                <RotatedErrorOutlineIcon />
                <p style={deleteModelStyles.modalBody}>
                  Are you sure you want to delete this form?
                </p>
              </div>
              <div style={deleteModelStyles.buttonContainer}>
                <CustomDeleteButton
                  name="CE1ID_DeleteFormYesButton"
                  variant="contained"
                  bgColor="#1A485E"
                  onClick={() => handleDeleteForm()}
                >
                  Yes
                </CustomDeleteButton>
                <CustomDeleteButton
                  name="CE1ID_DeleteFormNoButton"
                  variant="contained"
                  onClick={() => setOpenDeleteModel(false)}
                >
                  No
                </CustomDeleteButton>
              </div>
            </>
          </Box>
        </Modal>
        <div>
          <Formik initialValues={values} onSubmit={(values) => {}}>
            <Form>
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Grid container spacing={0.5}>
                      <Grid item xs={12}>
                        <div style={styles2.box_content2}>
                          <div style={styles2.box_heading2}>
                            <Box
                              container
                              spacing={0}
                              xs
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid item xs={1}>
                                <img
                                  style={styles2.profile_icon2}
                                  src={profileIcon}
                                  alt="profile icon"
                                />
                              </Grid>
                              <Grid style={styles2.patient_name2} item xs={7}>
                                <span>{capitalizeName(values?.name)}</span>
                              </Grid>
                              <Grid style={styles2.gender2} item xs={4}>
                                <span>{values?.age}</span>y, &nbsp;
                                <span>{values?.gender}</span>
                              </Grid>
                            </Box>
                          </div>
                          <div>
                            <Grid container spacing={0}>
                              <Grid item xs={12}>
                                <Grid container spacing={0}>
                                  <Grid
                                    item
                                    xs={10}
                                    style={{ margin: "0px !important" }}
                                  >
                                    <div style={styles2.field2}>
                                      <p
                                        style={{
                                          margineTop: "5px",
                                          fontWeight: "600",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        Address:{" "}
                                      </p>
                                      <span style={styles2.answer2}>
                                        {values?.address}
                                      </span>
                                    </div>
                                  </Grid>
                                  {values?.address === "-" && (
                                    <Grid item xs={2}>
                                      <div style={styles2.red_info_icon2}>
                                        <Tooltip
                                          id="tooltip1"
                                          placement="bottom"
                                          style={styles.tool_tip_style}
                                        />
                                        <p
                                          data-tooltip-id="tooltip1"
                                          data-tooltip-content="Please ensure you have entered this field in the PMS"
                                          style={{ margin: "0px" }}
                                        >
                                          <InfoOutlined
                                            name="CE1ID_AddressInfoIcon"
                                            style={styles.info_outlined_red}
                                          />
                                        </p>
                                      </div>
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <Grid container spacing={0}>
                                  <Grid item xs={10}>
                                    <div style={styles2.field2}>
                                      <p
                                        style={{
                                          fontWeight: "600",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        Medicare Number:{" "}
                                      </p>
                                      <span style={styles2.answer2}>
                                        {values?.medicareNumber}
                                      </span>
                                    </div>
                                  </Grid>
                                  {values?.medicareNumber === "-" && (
                                    <Grid item xs={2}>
                                      <div style={styles2.red_info_icon2}>
                                        <Tooltip
                                          id="tooltip2"
                                          place="bottom"
                                          style={styles.tool_tip_style}
                                        />
                                        <p
                                          data-tooltip-id="tooltip2"
                                          data-tooltip-content="Please ensure you have entered this field in the PMS"
                                          style={{ margin: "0px" }}
                                        >
                                          <InfoOutlined
                                            name="CE1ID_MedicareNumberInfoIcon"
                                            style={styles.info_outlined_red}
                                          />
                                        </p>
                                      </div>
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <Grid container spacing={0}>
                                  <Grid item xs={10}>
                                    <div style={styles2.field2}>
                                      <p
                                        style={{
                                          fontWeight: "600",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        Ethnicity:{" "}
                                      </p>
                                      <span style={styles2.answer2}>
                                        {values?.ethnicity}
                                      </span>
                                    </div>
                                  </Grid>
                                  {values?.ethnicity === "-" && (
                                    <Grid item xs={2}>
                                      <div style={styles2.red_info_icon2}>
                                        <Tooltip
                                          id="tooltip3"
                                          place="bottom"
                                          style={styles.tool_tip_style}
                                        />
                                        <p
                                          data-tooltip-id="tooltip3"
                                          data-tooltip-content="Please ensure you have entered this field in the PMS"
                                          style={{ margin: "0px" }}
                                        >
                                          <InfoOutlined
                                            name="CE1ID_EthnicityInfoIcon"
                                            style={styles.info_outlined_red}
                                          />
                                        </p>
                                      </div>
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <Grid container spacing={0}>
                                  <Grid item xs={10}>
                                    <div style={styles2.field2}>
                                      <p
                                        style={{
                                          fontWeight: "600",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        Indigenous Status:{" "}
                                      </p>
                                      <span style={styles2.answer2}>
                                        {values?.indigenousStatus}
                                      </span>
                                    </div>
                                  </Grid>
                                  {values?.indigenousStatus === "-" && (
                                    <Grid item xs={2}>
                                      <div style={styles2.red_info_icon2}>
                                        <Tooltip
                                          id="tooltip4"
                                          place="bottom"
                                          style={styles.tool_tip_style}
                                        />
                                        <p
                                          data-tooltip-id="tooltip4"
                                          data-tooltip-content="Please ensure you have entered this field in the PMS"
                                          style={{ margin: "0px" }}
                                        >
                                          <InfoOutlined
                                            name="CE1ID_IndigenousStatus"
                                            style={styles.info_outlined_red}
                                          />
                                        </p>
                                      </div>
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <div style={styles2.field2}>
                                  <p
                                    style={{
                                      fontWeight: "600",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    Date of the Presentation:
                                  </p>
                                  <span style={styles2.answer3}>
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                      disabled={
                                        consent === null ||
                                        consent === undefined ||
                                        isSubmitted
                                      }
                                    >
                                      <DatePicker
                                        slotProps={{
                                          textField: { size: "small" },
                                        }}
                                        sx={{
                                          "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                              borderRadius: "8px",
                                              height: "20px",
                                              paddingTop: "0px",
                                              border: "none",
                                            },
                                            backgroundColor: "#FFFFFF",
                                            borderRadius: "8px",
                                          },
                                        }}
                                        value={dateOfPresentation}
                                        onChange={handleDateOfPresentation}
                                        format="DD/MM/YYYY"
                                        minDate={previousWeekDate}
                                        maxDate={dayjs(exportDate)}
                                        disableFuture
                                        disabled={
                                          consent === null ||
                                          consent === undefined ||
                                          isSubmitted
                                        }
                                      />
                                    </LocalizationProvider>
                                  </span>
                                </div>
                              </Grid>
                              <Grid item xs={12}>
                                <div style={styles2.field2}>
                                  <p style={{ fontWeight: "600" }}>
                                    Time of the Presentation:
                                  </p>
                                  <span
                                    style={{
                                      paddingLeft: "5px",
                                      display: "flex",
                                    }}
                                  >
                                    {values.timeOfPresentation || "-"}
                                  </span>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div
                          style={styles.box_content}
                          styles={{
                            minHeight: "120px",
                          }}
                        >
                          <div style={styles.box_heading}>
                            <Grid container spacing={0}>
                              <Grid xs={11}>
                                Patient consent to submit UCC dataset
                              </Grid>
                              <Grid xs={1} style={styles.consent_head}>
                                <div
                                  style={{
                                    cursor: "pointer",
                                    display: "flex",
                                    justifyContent: "end",
                                    marginRight: "14px",
                                  }}
                                >
                                  <Tooltip
                                    html="<br>If 'Yes' is selected, the form responses will be included in the de-identified data submission.
 <br/><br/> If 'No' is selected, the form responses will not be included in the de-identified data submission. You may still complete the form for your own record keeping."
                                    multiline
                                    id="tooltipConsent"
                                    place="bottom"
                                    style={styles.tool_tip}
                                  />

                                  <p
                                    data-tooltip-id="tooltipConsent"
                                    style={{
                                      margin: "0px",
                                      position: "relative",
                                    }}
                                  >
                                    <InfoOutlined
                                      style={styles.info_outlined_white}
                                    />
                                  </p>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          <div>
                            <Grid
                              container
                              spacing={0}
                              className={consentError ? "custom-border" : ""}
                              styles={{
                                display: "flex",
                                height: "150px",
                                border: "red",
                              }}
                            >
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="radio-row-buttons-group"
                                style={{ paddingLeft: "13px" }}
                              >
                                <FormControlLabel
                                  name="CE1ID_consentYes"
                                  disabled={values.isSubmitted}
                                  checked={consent === true}
                                  onChange={() => {
                                    setConsentError(false);
                                    dispatch(setIsConsented(true));
                                    setFormValuesChanged(true);
                                  }}
                                  control={<Radio />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  name="CE1ID_consentNo"
                                  disabled={values.isSubmitted}
                                  checked={consent === false}
                                  onChange={() => {
                                    setConsentError(false);
                                    dispatch(setIsConsented(false));
                                    setFormValuesChanged(true);
                                  }}
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            </Grid>
                            <style>
                              {`.custom-border {
                                                                    border: 1px solid red;
                                                                    border-radius: 0px 0px 5px 5px;
                                                                    `}
                            </style>
                          </div>
                        </div>
                        <div
                          style={{
                            fontSize: "12px",
                            color: "#FF0000",
                            margin: "10px",
                            marginBottom: "0px",
                            visibility: consentError ? "visible" : "hidden",
                          }}
                        >
                          {" "}
                          Please select the patient consent status to complete
                          the form
                        </div>
                      </Grid>

                      <Grid item xs={12}>
                        <History patientId={values.externalId} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <div style={styles3.box_content}>
                      <div style={styles3.box_heading}>
                        Patient Characteristics
                      </div>
                      <div>
                        <Box spacing={2}>
                          <Paper
                            sx={{
                              height: 596,
                              overflowY: "auto",
                              overflowX: "hidden",
                              "&::-webkit-scrollbar": {
                                width: "6px",
                              },
                              "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#17263E",
                                height: "108px",
                                borderRadius: "4px",
                              },
                            }}
                          >
                            <div style={styles3.container}>
                              {Object.entries(questionsMapping3).map(
                                ([
                                  field,
                                  {
                                    question,
                                    options,
                                    type,
                                    sup_text,
                                    info_text,
                                  },
                                ]) => (
                                  <Grid key={field} item xs={12}>
                                    <Grid container spacing={0}>
                                      <Grid item xs={11}>
                                        <div key={field}>
                                          <div style={styles3.question}>
                                            {question}
                                            <span
                                              style={{
                                                color: "rgba(0, 0, 0, 0.50)",
                                                fontFamily: "Roboto",
                                                fontSize: "14px",
                                              }}
                                            >
                                              {sup_text}
                                            </span>
                                          </div>
                                          <div
                                            style={
                                              field ===
                                              "disabilityStatusIdsJson"
                                                ? styles.questionOpt3
                                                : styles.questionOpt3_1
                                            }
                                          >
                                            <QuestionOptions3
                                              field={field}
                                              options={options}
                                              type={type}
                                              formData={values}
                                              isDisableForm={isDisableForm}
                                              handleInputClick={
                                                handleInputClick
                                              }
                                              setFormData={(
                                                updatedFormData
                                              ) => {
                                                dispatch(
                                                  setPatientDatas(
                                                    updatedFormData
                                                  )
                                                );
                                                setFormValuesChanged(true);
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                      {info_text && (
                                        <Grid
                                          item
                                          xs={1}
                                          style={styles2.gray_info_icon2}
                                        >
                                          <div>
                                            <Tooltip
                                              id="tooltip5"
                                              place="bottom"
                                              style={styles.tool_tip_style}
                                            />
                                            <p
                                              data-tooltip-id="tooltip5"
                                              data-tooltip-content={info_text}
                                            >
                                              <InfoOutlined
                                                style={
                                                  styles.info_outlined_gray
                                                }
                                              />
                                            </p>
                                          </div>
                                        </Grid>
                                      )}
                                    </Grid>
                                  </Grid>
                                )
                              )}
                            </div>
                          </Paper>
                        </Box>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div style={styles1.box_content1}>
                      <div style={styles1.box_heading1}>
                        Patient Pathways and Clinical Details
                      </div>
                      <div>
                        <Box container spacing={2}>
                          <Paper
                            sx={{
                              height: 596,
                              overflow: "auto",
                              overflowX: "hidden",
                              "&::-webkit-scrollbar": {
                                width: "6px",
                              },
                              "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#17263E",
                                height: "108px",
                                borderRadius: "4px",
                              },
                            }}
                          >
                            <div style={styles1.container1}>
                              {Object.entries(questionsMapping1).map(
                                ([
                                  field,
                                  {
                                    question,
                                    options,
                                    type,
                                    sup_text,
                                    info_text,
                                  },
                                ]) => (
                                  <Grid key={field} item xs={12}>
                                    {!acuteClick &&
                                      field !== "causeOfInjury" && (
                                        <Grid container spacing={0}>
                                          <Grid item xs={11}>
                                            <div key={field}>
                                              <div style={styles1.question1}>
                                                {field !== "causeOfInjury" &&
                                                  question}
                                                <span
                                                  style={{
                                                    color:
                                                      "rgba(0, 0, 0, 0.50)",
                                                    fontFamily: "Roboto",
                                                    fontSize: "14px",
                                                  }}
                                                >
                                                  {sup_text}
                                                </span>
                                              </div>
                                              <div
                                                style={{ paddingTop: "15px" }}
                                              >
                                                {field !== "causeOfInjury" && (
                                                  <QuestionOptions1
                                                    field={field}
                                                    options={options}
                                                    acuteClick={acuteClick}
                                                    type={type}
                                                    isDisableForm={
                                                      isDisableForm
                                                    }
                                                    formData={values}
                                                    handleInputClick={
                                                      handleInputClick
                                                    }
                                                    setFormData={(
                                                      updatedFormData
                                                    ) => {
                                                      dispatch(
                                                        setPatientDatas(
                                                          updatedFormData
                                                        )
                                                      );
                                                      setFormValuesChanged(
                                                        true
                                                      );
                                                    }}
                                                  />
                                                )}
                                              </div>
                                            </div>
                                          </Grid>
                                          {field !== "causeOfInjury" &&
                                            info_text && (
                                              <Grid
                                                item
                                                xs={1}
                                                style={styles2.gray_info_icon2}
                                              >
                                                <div
                                                  styles={
                                                    styles2.red_info_icon2
                                                  }
                                                >
                                                  <Tooltip
                                                    id="tooltip6"
                                                    place="bottom"
                                                    style={
                                                      styles.tool_tip_style
                                                    }
                                                  />
                                                  <p
                                                    data-tooltip-id="tooltip6"
                                                    data-tooltip-content={
                                                      info_text
                                                    }
                                                  >
                                                    <InfoOutlined
                                                      style={
                                                        field ===
                                                        "causeOfInjury"
                                                          ? styles.info_outlined_yellow
                                                          : styles.info_outlined_gray
                                                      }
                                                    />
                                                  </p>
                                                </div>
                                              </Grid>
                                            )}
                                        </Grid>
                                      )}
                                    {acuteClick && (
                                      <Grid container spacing={0}>
                                        <Grid item xs={11}>
                                          <div key={field}>
                                            <div style={styles1.question1}>
                                              {question}
                                              <span
                                                style={{
                                                  color: "rgba(0, 0, 0, 0.50)",
                                                  fontFamily: "Roboto",
                                                  fontSize: "14px",
                                                }}
                                              >
                                                {sup_text}
                                              </span>
                                            </div>
                                            <div style={{ paddingTop: "15px" }}>
                                              <QuestionOptions1
                                                field={field}
                                                options={options}
                                                type={type}
                                                isDisableForm={isDisableForm}
                                                formData={values}
                                                handleInputClick={
                                                  handleInputClick
                                                }
                                                setFormData={(
                                                  updatedFormData
                                                ) => {
                                                  dispatch(
                                                    setPatientDatas(
                                                      updatedFormData
                                                    )
                                                  );
                                                  setFormValuesChanged(true);
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </Grid>
                                        {info_text && (
                                          <Grid
                                            item
                                            xs={1}
                                            style={styles2.gray_info_icon2}
                                          >
                                            <div
                                              styles={styles2.red_info_icon2}
                                            >
                                              <Tooltip
                                                id="tooltip6"
                                                place="bottom"
                                                style={styles.tool_tip_style}
                                              />
                                              <p
                                                data-tooltip-id="tooltip6"
                                                data-tooltip-content={info_text}
                                              >
                                                <InfoOutlined
                                                  style={
                                                    field === "causeOfInjury"
                                                      ? styles.info_outlined_yellow
                                                      : styles.info_outlined_gray
                                                  }
                                                />
                                              </p>
                                            </div>
                                          </Grid>
                                        )}
                                      </Grid>
                                    )}
                                  </Grid>
                                )
                              )}
                            </div>
                          </Paper>
                        </Box>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Form>
          </Formik>
        </div>
        <div style={styles.button}>
          <div
            style={{
              display: "flex",
              gap: "4px",
              alignItems: "center",
              justifyContent: "flex-end",
              cursor: "pointer",
            }}
          >
            <Tooltip
              id="toolTip"
              place="left"
              style={{
                zIndex: "9999",
                padding: "6px 16px",
                alignItems: "center",
                gap: "12px",
                lineHeight: "143%",
                color: "#FFFF",
                backgroundColor: "#1A485E",
              }}
            />
            <p
              data-tooltip-id="toolTip"
              data-tooltip-content="Next Data Submission Date"
            >
              <InfoOutlined
                style={{
                  width: "20px",
                  height: "20px",
                  fill: "rgba(0, 0, 0, 0.50)",
                  marginTop: "2px",
                }}
              />
            </p>

            <p style={{ padding: "5px" }}>
              {exportDate?.format("DD/MM/YYYY")} &nbsp;{" "}
              {countDown === 0 ? "(Today)" : `(${countDown} Days)`}
            </p>
          </div>
          <CustomButton
            name="CE1ID_SaveAndCloseButton"
            disabled={isDisableForm}
            variant="contained"
            onClick={() => {
              savePatientDataToDatabase();
              navigate("/home");
            }}
          >
            SAVE AND CLOSE
          </CustomButton>
        </div>
      </div>
    </div>
  );
}
const styles = {
  check: {
    color: "#1976D2",
  },
  check_1: {
    color: "rgba(0, 0, 0, 0.50)",
  },
  clinical: {
    alignItems: "start",
    marginBottom: "10px",
  },
  clinical_1: {
    PointerEvents: "none",
    alignItems: "start",
    marginBottom: "10px",
  },
  questionOpt3: {
    paddingTop: "25px",
  },
  questionOpt3_1: {
    paddingTop: "10px",
  },
  menu_props: {
    maxHeight: "40px",
    width: "100%",
  },
  label_menu_item: {
    padding: "6px 16px",
    width: "100%",
  },
  box_heading: {
    padding: "14px",
    borderRadius: "8px 8px 0px 0px",
    background: "#1A485E",
    fontSize: "20px",
    color: "white",
    fontFamily: "Roboto",
    fontWeight: "700",
  },
  box_content: {
    backgroundColor: "#FFFF",
    marginTop: "23px",
    boxShadow:
      "0px 4px 6px -1px rgba(0, 0, 0, 0.3), 0px 5px 8px 0px rgba(0, 0, 0, 0.22)",
    borderRadius: "0px 0px 8px 8px",
  },
  consent_head: {
    //padding: "11px 0px 11px 17px",
    margineLeft: "10px",
    position: "relative",
  },
  info_outlined_yellow: {
    width: "20px",
    height: "20px",
    fill: "#FFB400",
  },
  info_outlined_gray: {
    width: "20px",
    height: "20px",
    fill: "rgba(0, 0, 0, 0.50)",
  },
  info_outlined_red: {
    width: "20px",
    height: "20px",
    fill: "#C61010",
  },
  info_outlined_white: {
    width: "20px",
    height: "20px",
    fill: "#FFFF",
  },
  tool_tip_style: {
    zIndex: "9999",
    padding: "6px 16px",
    width: "100px",
    // padding: "6px 6px 6px 6px",
    alignItems: "center",
    lineHeight: "143%",
    color: "#FFF6F5",
    backgroundColor: "#190706",
    fontSize: "12px",
  },
  tool_tip: {
    position: "fixed !important",
    left: "",
    zIndex: "5",
    padding: "6px 16px",
    width: "234px",
    height: "264px",
    alignItems: "center",
    lineHeight: "143%",
    color: "#FFF6F5",
    backgroundColor: "#190706",
    fontSize: "14px",
  },
  container: {
    margin: "15px",
    padding: "35px",
    marginTop: "10px",
    marginBottom: "0px",
  },
  icon: {
    cursor: "pointer",
    justifyContent: "space-between",
    display: "flex",
  },
  icon_text: {
    paddingTop: "2px",
    fontWeight: "500",
    fontSize: "14px",
    fontFamily: "Roboto",
    paddingLeft: "10px",
    cursor: "pointer",
    color: "#1A485E",
  },
  top_bar: {
    marginTop: "10px",
  },
  top_bar_heading: {
    fontSize: "24px",
    fontWeight: "600",
    color: "#1A485E",
    marginBottom: "10px",
  },
  second_bar: {
    height: "52px",
    backgroundColor: "#F1F6F0",
    borderRadius: " 8px",
    marginTop: "30px",
  },
  second_bar_heading: {
    fontSize: "20px",
    fontWeight: "600",
    color: "#1A485E",
    paddingLeft: "8px",
    letterSpacing: "0.15px",
  },
  horizon_line: {
    backgroundColor: "#0000001A",
    marginTop: "20px",
  },
  button: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "20px",
    gap: "4px",
    alignItems: "center",
  },
  buttons: {
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },
};

const deleteModelStyles = {
  modalBody: {
    height: "32px",
    color: "#1A485E",
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "30px",
    letterSpacing: "0.15px",
    padding: "0px",
    margin: "0px",
  },
  heading: {
    padding: "0px",
    margin: "0px",
    fontSize: "20px",
    fontWeight: "700",
    color: "#FFFFFF",
    lineHeight: "24px",
    letterSpacing: "0.15px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "end",
    margin: "47px 39px 36px 0px",
    gap: "14px",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "row",
    margin: "40px 46px 0px 24px",
    alignItems: "center",
    gap: "19px",
  },
  modal: {
    position: "absolute",
    top: "184px",
    left: "50%",
    transform: "translateX(-50%)",
    minWidth: "45vw",
    maxHeight: "calc(100vh - 40px)",
    overflow: "auto",
    backgroundColor: "#fff",
    outline: "none",
    borderRadius: "8px",
  },
  modalHeader: {
    padding: "19px 22px 11px 24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#1A485E",
  },

  closeIcon: {
    padding: 0,
    fontSize: "32px",
    minWidth: "auto",
    width: "30px",
    height: "30px",
    fontWeight: "500",
    zIndex: "1",
  },
};
export default PatientFormPage;
