import React, { useEffect, useRef } from 'react'
import { PDFDocument } from 'pdf-lib'
import download from 'downloadjs'
import { questionsMapping1, questionsMapping3 } from 'utils/QuestionsMapping'
import { PrintOutlined, SaveAlt } from '@mui/icons-material'

const disabilityStatuses = [
  {
    value: 1,
    label: 'Sensory',
    example: '(e.g. sight, hearing, speech)',
  },
  {
    value: 2,
    label: 'Intellectual',
    example: '(e.g. difficulty learning or understanding)',
  },
  {
    value: 3,
    label: 'Physical',
    example:
      '(e.g. breathing difficulties, chronic or recurrent pain, blackouts or seizures, incomplete use of limbs)',
  },
  {
    value: 4,
    label: 'Psychosocial',
    example:
      '(e.g. nervous or emotional conditions, social or behavioral difficulties)',
  },
  {
    value: 5,
    label: 'Head injury, stroke, acquired brain injury',
    example: '',
  },
  {
    value: 6,
    label: 'Other',
    example: '',
  },
  {
    value: 0,
    label: 'None',
    example: '',
  },
]
const clinicalHandoverData = [
  {
    value: 1,
    label:
      'Sent to usual GP/primary care provider via fax, secure messaging etc.',
    example: '',
  },
  {
    value: 2,
    label: 'Uploaded to My Health Record',
    example: '',
  },
  {
    value: 3,
    label:
      'Provided to patient (patient declines handover to another service and/or patient cannot or does not wish to nominate a regular GP to handover to)',
    example: '',
  },
  {
    value: 0,
    label: 'Other',
    example: '',
  },
]

function convertDateFormat(inputDate, isName) {
  const dateObj = new Date(inputDate)
  const day = dateObj.getDate().toString().padStart(2, '0')
  const month = (dateObj.getMonth() + 1).toString().padStart(2, '0')
  const year = dateObj.getFullYear().toString()
  let newDateFormat = `${day}/${month}/${year}`
  if (isName === true) {
    newDateFormat = `${year}${month}${day}`
  }
  return newDateFormat
}

function isFlag(flag) {
  if (flag === true || flag === false) {
    return flag ? 'Yes' : 'No'
  } else if (typeof flag === 'string') {
    const lowerCaseFlag = flag.toLowerCase()
    if (lowerCaseFlag === 'true') {
      return 'Yes'
    } else if (lowerCaseFlag === 'false') {
      return 'No'
    }
  }
  return ' '
}

function idToStatuses(idInput, options) {
  if (!options || !Array.isArray(options) || options.length === 0) {
    return ''
  }

  let idArray

  if (Array.isArray(idInput)) {
      idArray = idInput.map((id) => parseInt(id, 10)).sort((a, b) => a - b)
      if (idArray.indexOf(0) !== -1) {
          let ele = idArray.shift();
          idArray.push(ele);
      }
  } else if (typeof idInput === 'string') {
    idArray = idInput
      .split(',')
      .map((id) => parseInt(id.trim(), 10))
          .sort((a, b) => a - b)
  } else {
    return ''
    }
  const labelsAndExamples = idArray
    .map((id) => {
        const status = options.find((status) => status.value === id)
        return status ? `${status.label} ${status.example || ''}`.trim() : null
    })
    .filter(Boolean)

  return labelsAndExamples.join('; ')
}

function idToStatusesOtherReferrels(idInput, options) {
    if (!options || !Array.isArray(options) || options.length === 0) {
        return ''
    }

    let idArray

    if (Array.isArray(idInput)) {
        idArray = idInput.map((id) => parseInt(id, 10)).sort((a, b) => a - b)
        if (idArray.indexOf(0) !== -1) {
            let ele = idArray.shift();
            idArray.push(ele);
            if (idArray.indexOf(5) !== -1) {
                let len = idArray.length;
                [idArray[len - 2], idArray[len - 1]] = [idArray[len - 1], idArray[len - 2]];
            }
        }
    } else if (typeof idInput === 'string') {
        idArray = idInput
            .split(',')
            .map((id) => parseInt(id.trim(), 10))
            .sort((a, b) => a - b)
    } else {
        return ''
    }
    const labelsAndExamples = idArray
        .map((id) => {
            const status = options.find((status) => status.value === id)
            return status ? `${status.label} ${status.example || ''}`.trim() : null
        })
        .filter(Boolean)

    return labelsAndExamples.join('; ')
}

const findLabelById = (names, options) => {
  if (Array.isArray(names) && names.length > 0) {
      const lables = options.map((value) => {
          return value.value
      });

      const mapping1 = names.map((value) => {
          return lables.indexOf(value)
      });

      mapping1.sort(function (a, b) { return a - b })

      const sorted = mapping1.map(value => lables[value])
      let prev = 0
      const num = sorted.map((value, index) => {
          if (index === 0) {
              prev = value.length
              return prev
          }
          prev = value.length + prev + 2
          return prev;
      })
      let lines = []
      let breakpoint = 101
      prev = 0
      for (let [index, value] of num.entries()) {
          if (value > breakpoint) {
              let line = sorted.slice(prev, index);
              lines.push(line.join("; "))
              prev = index;
              breakpoint = breakpoint + 101
          }
      }
      lines.push(sorted.slice(prev).join("; "));
      return lines.join(";\n")
  } else {
    return ''
  }
}

function labelByValue(value, options) {
  const selectedOption = options.find((option) => option.value === value)
  if (selectedOption) {
    return selectedOption.label
  }
  return ''
}

const PdfEditor = ({ dateOfPresentation, data }) => {
  const dopRef = useRef()
  const formattedPresentationDate = useRef()

  useEffect(() => {
    dopRef.current = dateOfPresentation
    updateFormattedPresentationDate()
  }, [dateOfPresentation])

  function updateFormattedPresentationDate() {
    const presentationDate = new Date(dopRef.current)
    const year = presentationDate.getFullYear()
    const month = (presentationDate.getMonth() + 1).toString().padStart(2, '0')
    const day = presentationDate.getDate().toString().padStart(2, '0')
    formattedPresentationDate.current = `${day}/${month}/${year}`
  }

  const token = localStorage.getItem('token')
  const myHeaders = new Headers()
  myHeaders.append('accept', '*/*')
  myHeaders.append('Authorization', `bearer ${token}`)
  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  }
  const updatePdfFields = async (userData, isPrint) => {
    try {
      const existingPdfBytes = await fetch(
        `/api/uccrecords/print-template`,
        requestOptions
      ).then((res) => res.arrayBuffer())

      const pdfDoc = await PDFDocument.load(existingPdfBytes)
      const form = pdfDoc.getForm()

      const fullName = form.getTextField('fullName')
      const age = form.getTextField('age')
      const gender = form.getTextField('gender')
      const address = form.getTextField('address')
      const mNumber = form.getTextField('mNumber')
      const dateOfPresentation = form.getTextField('dateOfPresentation')
      const languageIdsJson = form.getTextField('languageIdsJson')
      const interpreterRequired = form.getTextField('interpreterRequired')
      const disabilityStatusIdsJson = form.getTextField(
        'disabilityStatusIdsJson'
      )
      const isRegularGPAvailable = form.getTextField('isRegularGPAvailable')
      const uccEntryPoint = form.getTextField('uccEntryPoint')
      const alternativePlaces = form.getTextField('alternativePlaces')
      const conditionTypeIdsJson = form.getTextField('conditionTypeIdsJson')
      const causeOfInjury = form.getTextField('causeOfInjury')
      const clinicalHandover = form.getTextField('clinicalHandover')
      const episodeEndStatus = form.getTextField('episodeEndStatus')
      const otherReferralIdsJson = form.getTextField('otherReferralIdsJson')

      fullName.setText(data?.name)
      age.setText(String(data?.age))
      gender.setText(data?.gender)
      address.setText(data?.address)
      mNumber.setText(data?.medicareNumber)
      dateOfPresentation.setText(convertDateFormat(dopRef.current))
      languageIdsJson.setText(
        findLabelById(
          userData?.languageIdsJson,
          questionsMapping3.languageIdsJson.options
        )
      )
      interpreterRequired.setText(isFlag(userData?.interpreterRequired))
      disabilityStatusIdsJson.setText(
        idToStatuses(userData?.disabilityStatusIdsJson, disabilityStatuses)
      )
      isRegularGPAvailable.setText(isFlag(userData?.isRegularGPAvailable))
      uccEntryPoint.setText(
        labelByValue(
          userData?.uccEntryPoint,
          questionsMapping1.uccEntryPoint.options
        )
      )
      alternativePlaces.setText(
        labelByValue(
          userData?.alternativePlaces,
          questionsMapping1.alternativePlaces.options
        )
      )
      conditionTypeIdsJson.setText(
        idToStatuses(
          userData?.conditionTypeIdsJson,
          questionsMapping1.conditionTypeIdsJson.options
        )
      )
      causeOfInjury.setText(
        labelByValue(
          userData?.causeOfInjury,
          questionsMapping1.causeOfInjury.options
        )
      )
      clinicalHandover.setText(
        idToStatuses(userData?.clinicalHandover, clinicalHandoverData)
      )
      episodeEndStatus.setText(
        labelByValue(
          userData?.episodeEndStatus,
          questionsMapping1.episodeEndStatus.options
        )
      )
        otherReferralIdsJson.setText(
            idToStatusesOtherReferrels(
          userData?.otherReferralIdsJson,
          questionsMapping1.otherReferralIdsJson.options
        )
      )

      form.flatten()
      const pdfBytes = await pdfDoc.save()

      if (isPrint === true) {
        const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' })
        const pdfUrl = URL.createObjectURL(pdfBlob)
        const printWindow = window.open(pdfUrl, '_blank')
        printWindow.onload = () => {
          printWindow.print()
        }
      } else {
        const nameParts = data?.name.split(' ')
        const firstNameInitial =
          nameParts.length > 0 ? nameParts[0][0].toUpperCase() : ''
        const lastNameInitial =
          nameParts.length > 1
            ? nameParts[nameParts.length - 1][0].toUpperCase()
            : ''

        const dynamicFileName = `${convertDateFormat(
          dopRef.current,
          true
        )}_${firstNameInitial}${lastNameInitial}_ MedicareUCCSummary.pdf`
        download(pdfBytes, dynamicFileName, 'application/pdf')
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handlePrint = () => {
    updatePdfFields(data, true)
  }
  const handleDownload = () => {
    updatePdfFields(data, false)
  }

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          style={{ ...styles.icon, marginRight: '30px' }}
          onClick={handlePrint}
        >
          <PrintOutlined
            name='CE1ID_PrintIcon'
            style={{
              fill: '#17263E',
              width: '22px',
              height: '22px',
            }}
          />
          <div name='CE1ID_PrintIcon' style={styles.icon_text}>
            Print
          </div>
        </div>
        <div
          style={{ ...styles.icon, marginRight: '30px' }}
          onClick={handleDownload}
        >
          <SaveAlt
            name='CE1ID_SavePDFIcon'
            style={{
              fill: '#17263E',
              width: '22px',
              height: '22px',
            }}
          />
          <div name='CE1ID_SavePDFIcon' style={styles.icon_text}>
            Save PDF
          </div>
        </div>
      </div>
    </div>
  )
}

const styles = {
  icon: {
    cursor: 'pointer',
    justifyContent: 'space-between',
    display: 'flex',
  },
  icon_text: {
    paddingTop: '2px',
    fontWeight: '500',
    fontSize: '14px',
    fontFamily: 'Roboto',
    paddingLeft: '10px',
    cursor: 'pointer',
    color: '#1A485E',
  },
}

export default PdfEditor
