import dayjs from "dayjs";

import { userRequest2 } from 'userRequest'

 export const ExportDateFetch = async () => {
    try {
        const response = await userRequest2.get(`/api/ExportData`);
        const exportDateTime = dayjs(response?.data?.exportDateTime);
        const previousWeekDate = exportDateTime?.subtract(6, 'day');
        return { weekStartDateReturn: previousWeekDate, exportDateTimeReturn: exportDateTime, countDownReturn: response?.data?.countDown  }

    } catch (error) {
        console.log('Error fetching export data: ', error);
    }
}

