import { useEffect, useState } from 'react'
import icon1 from 'Assets/images/LoadingIcon/1.svg'
import icon2 from 'Assets/images/LoadingIcon/2.svg'
import icon3 from 'Assets/images/LoadingIcon/3.svg'
import icon4 from 'Assets/images/LoadingIcon/4.svg'
import icon5 from 'Assets/images/LoadingIcon/5.svg'
import icon6 from 'Assets/images/LoadingIcon/6.svg'
import icon7 from 'Assets/images/LoadingIcon/7.svg'
import icon8 from 'Assets/images/LoadingIcon/8.svg'

const LoadingIcon = () => {
    const [currentIcon, setCurrentIcon] = useState(1)
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIcon((prevIcon) => (prevIcon === 8 ? 1 : prevIcon + 1))
        }, 100)

        return () => clearInterval(interval)
    }, [])

    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
    }

    const iconContainerStyle = {
        position: 'relative',
        width: '50px',
        height: '50px',
    }

    const iconStyle = {
        width: '81px',
        height: '81px',
        position: 'absolute',
        top: '0',
        left: '0',
        opacity: 0,
        transition: 'opacity 0.2s',
    }

    const activeIconStyle = {
        opacity: 1,
    }

    const icons = [icon1, icon2, icon3, icon4, icon5, icon6, icon7, icon8]

    return (
        <div style={containerStyle}>
            <div style={iconContainerStyle}>
                {icons.map((icon, index) => (
                    <img
                        key={index + 1}
                        src={icon}
                        alt={`Icon ${index + 1}`}
                        style={{
                            ...iconStyle,
                            ...(currentIcon === index + 1 ? activeIconStyle : {}),
                        }}
                    />
                ))}
            </div>
        </div>
    )
}

export default LoadingIcon
