import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { createStore } from 'redux'
import allReducers from 'Services/reducers'
import { Provider } from 'react-redux'

const myStore = createStore(allReducers)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <Provider store={myStore}>
        <ToastContainer />
        <App />
    </Provider>
)